import { call, put, takeEvery } from "redux-saga/effects";
import {
  addPoiFail,
  addPoiSuccess,
  deletePoiFail,
  deletePoiSuccess,
  editPoiFail,
  editPoiSuccess,
  getPoisFail,
  getPoisSuccess,
} from "./actions";
import { ADD_POI, DELETE_POI, EDIT_POI, GET_POIS } from "./actionTypes";
import {
  deletePoi,
  getPoiList,
  postPoi,
  updatePoi,
} from "../../helpers/backend_helper";

// Login Redux States

function* fetchPois() {
  try {
    const response = yield call(getPoiList);
    yield put(getPoisSuccess(response));
  } catch (error) {
    yield put(getPoisFail(error));
  }
}
function* addPoi({ payload: poi }) {
  try {
    const response = yield call(postPoi(poi));
    yield put(addPoiSuccess(response));
  } catch (err) {
    yield put(addPoiFail(err));
  }
}
function* onUpdatePoi({ payload: poi }) {
  try {
    const response = yield call(updatePoi, poi);
    yield put(editPoiSuccess(response));
  } catch (error) {
    yield put(editPoiFail(error));
  }
}

function* onDeletePoi({ payload: poi }) {
  try {
    const response = yield call(deletePoi, poi);
    yield put(deletePoiSuccess(response));
  } catch (error) {
    yield put(deletePoiFail(error));
  }
}

function* poiSaga() {
  yield takeEvery(GET_POIS, fetchPois);
  yield takeEvery(ADD_POI, addPoi);
  yield takeEvery(EDIT_POI, onUpdatePoi);
  yield takeEvery(DELETE_POI, onDeletePoi);
}

export default poiSaga;
