import React, {useState} from 'react'
import {Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {deUmlaut, isLatitude, isLongitude} from "../../../helpers/common_functions_helper";
import {NotificationManager} from "react-notifications";
import {poiTypes} from "../../../types/PoiTypes";
import {editPoi, getPois} from "../../../store/pois/actions";
import {useDispatch, useSelector} from "react-redux";
import {getImageUrl, postImage} from "../../../helpers/backend_helper";


const PoiEdit = (props) =>{
    const {editedPoi, modal, setModal,setEditedPoi} = props;


    const [checkLat, setCheckLat] = useState(false);
    const [checkLng, setCheckLng] = useState(false);
    const [checkPoiName, setCheckPoiName] = useState(false);
    const [currentImage, setCurrentImage] = useState(editedPoi.image);
    const [selects, setSelects] = useState(editedPoi.triggerPoints);
    const [selectedAudios, setSelectedAudios] = useState([]);
    const dispatch = useDispatch();

    const { audios } = useSelector((state) => ({
        audios: state.audios.list.sort((a,b) => a.name.localeCompare(b.name)),
    }));

    const createAudios = (point, index) => {
        return (
            <select
                className="form-control"
                defaultValue={audios.filter((audio) => audio.id === point.audio)[0].id}
                onChange={(e) => handleAudioChange(index, e.target.value)}
            >
                {audios.map((audio,index) =><option key={index} value={audio.id}>{audio.name}</option>)}
            </select>
        );
    };
    const handleAudioChange = (index, value) => {
        const updatedSelects = [...selectedAudios];
        updatedSelects[index] = {audio: value}
        setSelectedAudios(updatedSelects);
    };
    const createOptions = () => {
        let component = [];
        poiTypes.forEach((type, index) => {
            component.push(<option key={index}>{type.value}</option>);
        });
        return component;
    };
    const getValueByKey = (type) => {
        const filtered = poiTypes.filter((poiType) => poiType.key === type)[0];
        return filtered ?  filtered.value : "";
    };
    const handleTriggerPointLat = (index, value) => {
        const updatedSelects = [...selects];
        updatedSelects[index].coordinate.lat = value;
        setSelects(updatedSelects);
    };
    const handleTriggerPointLng = (index, value) => {
        const updatedSelects = [...selects];
        updatedSelects[index].coordinate.lng = value;
        setSelects(updatedSelects);
    };
    const handleAddSelect = () => {
        setSelects([
            ...selects,
            { coordinate: { lat: "", lng: "" }, audio: audios[0].id },
        ]);
    };

    const toggle = () => {
        if(modal){
            setEditedPoi(null)
        }
        setModal(!modal);
    };
    const handleDeleteSelect = (index) => {
        const updatedSelects = [...selects];
        updatedSelects.splice(index, 1);
        setSelects(updatedSelects);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        if (data.get("poiName") === "" || data.get("poiName") === undefined) {
            setCheckPoiName(false);
        } else setCheckPoiName(true);
        setCheckLat(isLatitude(data.get("lat")));
        setCheckLng(isLongitude(data.get("lng")));
        if(isLatitude(data.get("lat")) && isLongitude(data.get("lng"))){
            NotificationManager.info("Update Started!");
            if (data.get("image").name !== "") {
                getImageUrl(deUmlaut(data.get("image").name), "pois").then((imageResponse)=>{
                    if(imageResponse.status === 200) {
                        const url = imageResponse.data.url;
                        postImage(url, data.get("image")).then((postImageResponse) => {
                            if (postImageResponse.status === 200) {
                                const object = {
                                    id: editedPoi.id,
                                    name: data.get("poiName"),
                                    description: data.get("description"),
                                    image: new URL("https://locco-content-bucket231847-staging.s3.eu-central-1.amazonaws.com/public/images/pois/" + deUmlaut(data.get("image").name).toString()),
                                    location: {
                                        lat: data.get("lat"),
                                        lng: data.get("lng"),
                                    },
                                    type: poiTypes.filter((type) => type.value === data.get("type"))[0]["key"],
                                    notificationTitle: data.get("notificationTitle"),
                                    triggerPoints: selects,
                                };
                                dispatch(editPoi(object));
                                dispatch(getPois());
                                NotificationManager.success("Update Successful!");
                            }
                        })
                    }
                })
            } else {
                const object = {
                    id: editedPoi.id,
                    name: data.get("poiName"),
                    description: data.get("description"),
                    image: editedPoi.image,
                    location: {
                        lat: data.get("lat"),
                        lng: data.get("lng"),
                    },
                    type: poiTypes.filter((type) => type.value === data.get("type"))[0][
                        "key"
                        ],
                    notificationTitle: data.get("notificationTitle"),
                    triggerPoints: selects,
                };
                dispatch(editPoi(object));
                dispatch(getPois());
                NotificationManager.success("Update Successful!");
            }
        }
        else {
            NotificationManager.error("Please Check Input Values!");
        }
    };
    return(
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
                Edit Poi
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                        <Label
                            htmlFor="poiName"
                            className="col-form-label col-lg-2"
                        >
                            Poi Name
                        </Label>
                        <Col lg="10">
                            <Input
                                id="poiName"
                                name="poiName"
                                type="text"
                                className="form-control"
                                placeholder="Enter Poi Name..."
                                defaultValue={editedPoi.name}
                                valid={checkPoiName}
                            />
                            {checkPoiName === true && (
                                <FormFeedback valid>Valid Name</FormFeedback>
                            )}
                        </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                            Poi Description
                        </Label>
                        <Col lg="10">
                            <Input
                                id="description"
                                name="description"
                                type="textarea"
                                defaultValue={editedPoi.description}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">Poi Image</Label>
                        <Col lg="10">
                            <Row>
                                <Col md={12} className="pr-0">
                                    <div className="mt-12 mt-md-0">
                                        <Input
                                            id="image"
                                            name="image"
                                            type="file"
                                            accept="image/*"
                                            className="form-control mb-3"
                                            onChange={(event) =>
                                                setCurrentImage(
                                                    URL.createObjectURL(event.target.files[0])
                                                )
                                            }
                                        />
                                        {editedPoi.image && (
                                            <img
                                                width={64}
                                                height={64}
                                                src={currentImage}
                                                alt="poi"
                                            />
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <div className="inner-repeater mb-4">
                            <div className="inner form-group mb-0 row">
                                <Label className="col-form-label col-lg-2">
                                    Coordinates
                                </Label>
                                <div
                                    className="inner col-lg-10 ml-md-auto"
                                    id="repeater"
                                >
                                    <div
                                        id={"nested"}
                                        className="mb-3 row align-items-center"
                                    >
                                        <Col md="6">
                                            <Input
                                                id="lat"
                                                name="lat"
                                                type="number"
                                                min="0.000000"
                                                step="0.0000001"
                                                max="90.000000"
                                                presicion={8}
                                                className="inner form-control"
                                                placeholder="Enter Latitude..."
                                                valid={checkLat}
                                                defaultValue={editedPoi.location.lat}
                                            />
                                            {checkLat === true && (
                                                <FormFeedback valid>
                                                    Valid Coordinate
                                                </FormFeedback>
                                            )}
                                        </Col>
                                        <Col md="6">
                                            <Input
                                                id="lng"
                                                name="lng"
                                                type="number"
                                                min="0.000000"
                                                step="0.0000001"
                                                max="90.000000"
                                                presicion={8}
                                                className="inner form-control"
                                                placeholder="Enter Longitude..."
                                                valid={checkLng}
                                                defaultValue={editedPoi.location.lng}
                                            />
                                            {checkLng === true && (
                                                <FormFeedback valid>
                                                    Valid Coordinate
                                                </FormFeedback>
                                            )}
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                        <Label
                            htmlFor="notificationTitle"
                            className="col-form-label col-lg-2"
                        >
                            Notification Title
                        </Label>
                        <Col lg="10">
                            <Input
                                id="notificationTitle"
                                name="notificationTitle"
                                type="text"
                                className="form-control"
                                defaultValue={editedPoi.notificationTitle}
                                placeholder="Notification Title"
                            />
                        </Col>
                    </FormGroup>
                    <div className="inner form-group mb-0 row">
                        <Label className="col-form-label col-lg-2">
                            Trigger Points
                        </Label>
                        <div className="inner col-lg-10 ml-md-auto">
                            <div className="mb-3 row align-items-center">
                                {selects.map((point, index) => (
                                    <Row className="mt-1" key={index}>
                                        <div
                                            className="inner col-lg-10 ml-md-auto"
                                            id="repeater"
                                            key={index}
                                        >
                                            <div
                                                id={"nested"}
                                                className="mb-3 row align-items-center"
                                            >
                                                <Col md="12" key={index}>
                                                    <Input
                                                        id="lat"
                                                        name="lat"
                                                        type="number"
                                                        min="0.000000"
                                                        step="0.0000001"
                                                        max="90.000000"
                                                        presicion={8}
                                                        onChange={(event) =>
                                                            handleTriggerPointLat(
                                                                index,
                                                                event.target.value
                                                            )
                                                        }
                                                        defaultValue={point.coordinate.lat}
                                                        className="inner form-control"
                                                        placeholder="Enter Latitude..."
                                                    />
                                                    <FormFeedback valid>
                                                        Valid Coordinate
                                                    </FormFeedback>
                                                </Col>
                                                <Col md="12">
                                                    <Input
                                                        id="lng"
                                                        name="lng"
                                                        type="number"
                                                        min="0.000000"
                                                        step="0.0000001"
                                                        max="90.000000"
                                                        presicion={8}
                                                        defaultValue={point.coordinate.lng}
                                                        onChange={(event) =>
                                                            handleTriggerPointLng(
                                                                index,
                                                                event.target.value
                                                            )
                                                        }
                                                        className="inner form-control"
                                                        placeholder="Enter Longitude..."
                                                    />
                                                    <FormFeedback valid>
                                                        Valid Coordinate
                                                    </FormFeedback>
                                                </Col>
                                                <Col md="12">{createAudios(point, index)}</Col>
                                            </div>
                                        </div>
                                        <Col md="2">
                                            <Button
                                                color="danger"
                                                onClick={() => handleDeleteSelect(index)}
                                            >
                                                -
                                            </Button>
                                            <Button onClick={handleAddSelect} color="success">
                                                +
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        </div>
                    </div>

                    <FormGroup className="mb-4" row>
                        <Row className="mb-3">
                            <label
                                htmlFor="taskbudget"
                                className="col-form-label col-lg-2"
                            >
                                Type
                            </label>
                            <div className="col-md-10">
                                <Input
                                    id="type"
                                    name="type"
                                    type="select"
                                    defaultValue={getValueByKey(editedPoi.type)}
                                >
                                    {createOptions()}
                                </Input>
                            </div>
                        </Row>
                    </FormGroup>
                    <Row className="justify-content-end">
                        <Col lg="10">
                            <Button type="submit" color="primary">
                                Edit POI
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )

}
export default PoiEdit
