import PropTypes from "prop-types";
import React, { useRef } from "react";
import { map } from "lodash";
import {
  Button,
  Card,
  CardBody,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const AudioCard = (props) => {
  const {audios,setEditedAudio,setDeletedAudio, setModalOpen, setDeleteModal} = props
  const playerRef = useRef();

  const createLayout = (key, audio) => {
    return (
      <Col xl="4" sm="6" key={key}>
        <Card>
          <CardBody>
            <div className="d-flex">
              <div className=" me-4">
                <img
                  className="rounded-circle"
                  src={audio.image}
                  width={48}
                  height={48}
                  alt="Header Avatar"
                />
              </div>
              <div className="flex-grow-1 overflow-hidden">
                <h5 className="text-truncate font-size-15">{audio.name}</h5>
                <p className="text-muted mb-4">{audio.description}</p>
              </div>
            </div>
            <AudioPlayer
              ref={playerRef}
              style={{ borderRadius: "1rem" }}
              autoPlay={false}
              src={audio.url}
              showSkipControls={false}
              autoPlayAfterSrcChange={false}
              showJumpControls={false}
              showDownloadProgress={true}
            />
          </CardBody>
          <div className="px-4 py-3 border-top">
            <ul className="list-inline mb-0">
              <li className="list-inline-item me-3">
                <Button
                  color="success"
                  className="inner"
                  onClick={() => {
                    setEditedAudio(audio);
                    setModalOpen(true)
                  }}
                >
                  Edit
                </Button>
              </li>{" "}
              <li className="list-inline-item me-3">
                <Button
                  color="danger"
                  onClick={() => {
                    setDeletedAudio(audio);
                    setDeleteModal(true)
                  }}
                  className="inner"
                >
                  Delete
                </Button>
              </li>{" "}
              <li className="list-inline-item me-3" id="dueDate">
                <i className="bx bx-calendar me-1" /> {audio.date}
                <UncontrolledTooltip placement="top" target="dueDate">
                  Date
                </UncontrolledTooltip>
              </li>{" "}
            </ul>
          </div>
        </Card>
      </Col>
    );
  };

  return (
    <React.Fragment>
      {map(audios, (audio, key) => createLayout(key, audio))}
    </React.Fragment>
  );
};

AudioCard.propTypes = {
  projects: PropTypes.array,
};

export default AudioCard;
