export const GET_MAILS_LIST = "GET_MAILS_LIST";
export const GET_MAILS_LIST_SUCCESS = "GET_MAILS_LIST_SUCCESS";
export const GET_MAILS_LIST_FAIL = "GET_MAILS_LIST_FAIL";

//select folder
export const SELECT_FOLDER = "SELECT_FOLDER";
export const SELECT_FOLDER_SUCCESS = "SELECT_FOLDER_SUCCESS";
export const SELECT_FOLDER_FAIL = "SELECT_FOLDER_FAIL";

//Selected mail
export const GET_SELECTED_MAILS = "GET_SELECTED_MAILS";
export const GET_SELECTED_MAILS_SUCCESS = "GET_SELECTED_MAILS_SUCCESS";
export const GET_SELECTED_MAILS_FAIL = "GET_SELECTED_MAILS_FAIL";

//set folder on selected mails
export const SET_FOLDER_SELECTED_MAILS = "SET_FOLDER_SELECTED_MAILS";
export const SET_FOLDER_SELECTED_MAILS_SUCCESS =
  "SET_FOLDER_SELECTED_MAILS_SUCCESS";
export const SET_FOLDER_SELECTED_MAILS_FAIL = "SET_FOLDER_SELECTED_MAILS_FAIL";

//update mail
export const UPDATE_MAIL = "UPDATE_MAIL";
export const UPDATE_MAIL_SUCCESS = "UPDATE_MAIL_SUCCESS";
export const UPDATE_MAIL_FAIL = "UPDATE_MAIL_FAIL";
