export const GET_AUDIOS = "GET_AUDIOS";
export const GET_AUDIOS_SUCCESS = "GET_AUDIOS_SUCCESS";

export const GET_AUDIOS_ERROR = "GET_AUDIOS_ERROR";

export const ADD_AUDIO = "ADD_AUDIO";
export const ADD_AUDIO_SUCCESS = "ADD_AUDIO_SUCCESS";
export const ADD_AUDIO_ERROR = "ADD_AUDIO_ERROR";

export const EDIT_AUDIO = "EDIT_AUDIO";
export const EDIT_AUDIO_SUCCESS = "EDIT_AUDIO_SUCCESS";
export const EDIT_AUDIO_ERROR = "EDIT_AUDIO_ERROR";

export const DELETE_AUDIO = "DELETE_AUDIO";
export const DELETE_AUDIO_SUCCESS = "DELETE_AUDIO_SUCCESS";
export const DELETE_AUDIO_ERROR = "DELETE_AUDIO_ERROR";
