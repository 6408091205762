export const poiTypes = [
  { key: "museum", value: "Museum" },
  { key: "airport", value: "Airport"},
  { key: "art", value: "Art Gallery" },
  { key: "theatre", value: "Theatre" },
  { key: "university", value: "University" },
  { key: "mountain", value: "Mountain" },
  { key: "river", value: "River" },
  { key: "region", value: "Region" },
  { key: "castle", value: "Castle" },
  { key: "fort", value: "Fort" },
  { key: "landmark", value: "Historical Landmark" },
  { key: "activity", value: "Activity" },
  { key: "church", value: "Church" },
  { key: "mosque", value: "Mosque" },
  { key: "park", value: "Park" },
  { key: "lake", value: "Lake"},
  { key: "monastery", value: "Monastery" },
  { key: "nature", value: "Nature"},
  { key: "fountain", value: "Fountain" },
  { key: "monument", value: "Monument" },
  { key: "boulevard", value: "Boulevard" },
  { key: "bridge", value: "Bridge" },
  { key: "square", value: "Square" },
  { key: "synagogue", value: "Synagogue" },
  { key: "casino", value: "Casino" },
  { key: "restaurant", value: "Restaurant" },
  { key: "cafe", value: "Cafe" },
  { key: "bar", value: "Bar" },
  { key: "bakery", value: "Bakery"},
  { key: "fair", value: "Fair"},
  { key: "cave", value: "Cave"},
  { key: "community", value: "Community"},
  { key: "port", value: "Port"},
  { key: "vineyard", value: "Vineyard" },
  { key: "club", value: "Nightclub" },
  { key: "shop", value: "Local Shop" },
  { key: "pass", value: "Pass" },
  { key: "stadium", value: "Stadium" },
  { key: "pizzeria", value: "Pizzeria"},
  { key: "cable_car", value: "Cable Car"},
  { key: "sport", value: "Sports"},
  { key: "tunnel", value: "Tunnel"},
  { key: "waterfall",value:"Waterfall"},
  { key: "theme_park",value:"Theme Park"},
  { key: "city", value: "City" },
  { key: "building", value: "Building" },
  { key: "other", value: "Other" },
];
