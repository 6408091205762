import {
  VERIFICATION_USER,
  VERIFICATION_USER_SUCCESSFUL,
  VERIFICATION_USER_FAILED,
} from "./actionTypes";

export const userVerification = (user, history) => {
  return {
    type: VERIFICATION_USER,
    payload: { user, history },
  };
};

export const userVerificationSuccess = (message) => {
  return {
    type: VERIFICATION_USER_SUCCESSFUL,
    payload: message,
  };
};

export const userVerificationError = (message) => {
  return {
    type: VERIFICATION_USER_FAILED,
    payload: message,
  };
};
