import React, {useState} from 'react'
import {Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {getAudioUrl, getImageUrl, postAudio, postImage} from "../../../helpers/backend_helper";
import {deUmlaut} from "../../../helpers/common_functions_helper";
import {editAudio, getAudios} from "../../../store/audio/actions";
import {NotificationManager} from "react-notifications";
import {useDispatch} from "react-redux";


const AudioEdit = (props) =>{
    const {editedAudio,modal,setModal,setEditedAudio} = props;
    const [checkAudioName, setCheckAudioName] = useState(false);
    const [currentImage, setCurrentImage] = useState();
    const dispatch =useDispatch()

    const toggle = () => {
        if(modal){
            setEditedAudio(null)
        }
        setModal(!modal);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        if (data.get("audioName") === "" || data.get("audioName") === undefined) {
            setCheckAudioName(false);
        } else setCheckAudioName(true);
        let object = {};
        if (data.get("audio").name !== "") {
            getAudioUrl(deUmlaut(data.get("audio").name)).then((audioUrlResponse) => {
                const url = audioUrlResponse.data.url;
                if (url) {
                    postAudio(url, data.get("audio")).then((postAudioResponse) => {
                        if (postAudioResponse.status === 200) {

                            object.fileName = data.get("audio").name.charAt(0).toUpperCase() + data.get("audio").slice(1);
                            object.duration = data.get("audio").duration;
                            object.url = "https://locco-content-bucket231847-staging.s3.eu-central-1.amazonaws.com/public/audios/" + deUmlaut(data.get("audio").name);

                            checkImageAndEdit(data, object);
                        }
                    });
                }
            });
        } else {
            checkImageAndEdit(data, object);
        }
    };

    const checkImageAndEdit = (data, object) => {
        if (data.get("image").name !== "") {
            getImageUrl(deUmlaut(data.get("image").name),"audios").then((imageResponse) =>{
                if(imageResponse.status === 200){
                    const url = imageResponse.data.url;
                    postImage(url, data.get("image")).then((postImageResponse) => {
                        if (postImageResponse.status === 200) {
                            object.id =  editedAudio.id;
                            object.image= new URL("https://locco-content-bucket231847-staging.s3.eu-central-1.amazonaws.com/public/images/audios/" + deUmlaut(data.get("image").name)).toString();
                            object.imageFile = data.get("image").name;
                            object.name = deUmlaut(data.get("audioName"));
                            object.description = data.get("description");
                            object.imageAttribution = data.get("imageAttribution");
                            object.date = new Date().toISOString();
                            dispatch(editAudio(object));
                            dispatch(getAudios());
                            NotificationManager.success("Update Successful!");
                        }
                    });
                }
            })
        } else {
            object = {
                id: editedAudio.id,
                name: data.get("audioName"),
                description: data.get("description"),
                imageAttribution : data.get("imageAttribution"),
                image: editedAudio.image,
                date: new Date().toISOString(),
                url: data.get("audio").name !== "" ? new URL(
                    "https://locco-content-bucket231847-staging.s3.eu-central-1.amazonaws.com/public/audios/" +
                    deUmlaut(data.get("audio").name)) : editedAudio.url,
                fileName: editedAudio.fileName,
            };
            dispatch(editAudio(object));
            dispatch(getAudios());
            NotificationManager.success("Update Successful!");
        }
    };
    return(
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            Edit Audio
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit}>
              <FormGroup className="mb-4" row>
                <Label htmlFor="audioName" className="col-form-label col-lg-2">
                  Audio Name
                </Label>
                <Col lg="10">
                  <Input
                      id="audioName"
                      name="audioName"
                      type="text"
                      className="form-control"
                      placeholder="Enter Audio Name..."
                      defaultValue={editedAudio.name}
                      valid={checkAudioName}
                  />
                  {checkAudioName === true && (
                      <FormFeedback valid>Valid Name</FormFeedback>
                  )}
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label className="col-form-label col-lg-2">
                  Audio Description
                </Label>
                <Col lg="10">
                  <Input
                      id="description"
                      name="description"
                      type="textarea"
                      defaultValue={editedAudio.description}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label className="col-form-label col-lg-2">Audio Image</Label>
                <Col lg="10">
                  <Row>
                    <Col md={12} className="pr-0">
                      <div className="mt-12 mt-md-0">
                        <Input
                            id="image"
                            name="image"
                            type="file"
                            accept="image/*"
                            className="form-control mb-3"
                            onChange={(event) =>
                                setCurrentImage(
                                    URL.createObjectURL(event.target.files[0])
                                )
                            }
                        />
                        {editedAudio.image && !currentImage && (
                            <img
                                width={64}
                                height={64}
                                src={editedAudio.image}
                                alt="audio"
                            />
                        )}
                        {editedAudio.image && currentImage && (
                            <img
                                width={64}
                                height={64}
                                src={currentImage}
                                alt="audio"
                            />
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label className="col-form-label col-lg-2">Audio File</Label>
                <Col lg="10">
                  <Row>
                    <Col md={12} className="pr-0">
                      <div className="mt-12 mt-md-0">
                        <Input
                            id="audio"
                            name="audio"
                            type="file"
                            accept="audio/*"
                            className="form-control mb-3"
                        />
                        {editedAudio.fileName}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                    <Label
                        htmlFor="imageAttribution"
                        className="col-form-label col-lg-2"
                    >
                        Image Attribution
                    </Label>
                    <Col lg="10">
                        <Input
                            id="imageAttribution"
                            name="imageAttribution"
                            type="text"
                            className="form-control"
                            placeholder="Enter Image Attribution..."
                        />
                    </Col>
              </FormGroup>
              <Row className="justify-content-end">
                <Col lg="10">
                  <Button type="submit" color="primary">
                    Edit Audio
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
    )
}
export default AudioEdit
