import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  UPDATE_PROFILE_IMAGE,
  UPDATE_PROFILE_IMAGE_SUCCESS,
  UPDATE_PROFILE_IMAGE_ERROR,
  UPDATE_PROFILE_PASSWORD_ERROR,
  UPDATE_PROFILE_PASSWORD_SUCCESS,
  UPDATE_PROFILE_PASSWORD,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  currentUser: {},
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
      };
    case GET_PROFILE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload };
      break;
    case UPDATE_PROFILE_IMAGE:
      state = { ...state };
      break;
    case UPDATE_PROFILE_IMAGE_SUCCESS:
      state = { ...state, success: action.payload };
      break;
    case UPDATE_PROFILE_IMAGE_ERROR:
      state = { ...state, error: action.payload };
      break;
    case UPDATE_PROFILE_PASSWORD:
      state = { ...state };
      break;
    case UPDATE_PROFILE_PASSWORD_SUCCESS:
      state = { ...state, success: action.payload };
      break;
    case UPDATE_PROFILE_PASSWORD_ERROR:
      state = { ...state, error: action.payload };
      break;
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
