export const GET_MOST_POIS = "GET_MOST_POIS";
export const GET_MOST_POIS_SUCCESS = "GET_MOST_POIS_SUCCESS";

export const GET_MOST_POIS_ERROR = "GET_MOST_POIS_ERROR";

export const GET_MOST_USERS = "GET_MOST_USERS";
export const GET_MOST_USERS_SUCCESS = "GET_MOST_USERS_SUCCESS";

export const GET_MOST_USERS_ERROR = "GET_MOST_USERS_ERROR";


