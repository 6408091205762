import React from 'react';
import {Card, CardBody, CardTitle} from "reactstrap";
import useWindowDimensions from "../../helpers/common_functions_helper";
import Chart from 'react-apexcharts';
const TopUsers = (props) =>{
    const {mostUsers} = props
    const labels = mostUsers.map((user) => user.email)
    const counts = mostUsers.map((user) => user.count)
    const { height, width } = useWindowDimensions();

    const data = {
        labels: labels,
        series: counts,
    };
    const options = {
        chart: {
            type: 'bar',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
            },
        },
        dataLabels: {
            enabled: true,
        },
        xaxis: {
            categories: data.labels,
        },
    };

    return(
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle>Most Active Users</CardTitle>
                    <Chart options={options} series={[{ name:"Listen Count" ,data: data.series }]} type="bar" width={width/3} height={height/3}/>
                </CardBody>
            </Card>
        </React.Fragment>
    )

}
export default TopUsers;
