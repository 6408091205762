import {useEffect, useState} from "react";

export const getImageAsBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};
export const isLatitude = (lat) => {
  let latF = parseFloat(lat);
  if (isNaN(latF)) return false;
  return latF >= -90 && latF <= 90;
};

export const isLongitude = (lng) => {
  let lonF = parseFloat(lng);
  if (isNaN(lonF)) return false;
  return lonF >= -180 && lonF <= 180;
};

export const deUmlaut =(value) =>{
  value = value.toLowerCase();
  value = value.replace(/ä/g, 'ae');
  value = value.replace(/ö/g, 'oe');
  value = value.replace(/ü/g, 'ue');
  value = value.replace(/ß/g, 'ss');
  value = value.replace(/ /g, '-');
  value = value.replace(/,/g, '');
  value = value.replace(/\(/g, '');
  value = value.replace(/\)/g, '');
  value = value.charAt(0).toUpperCase() + value.slice(1);
  return value;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
