import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  EDIT_PROFILE,
  GET_PROFILE,
  UPDATE_PROFILE_IMAGE,
  UPDATE_PROFILE_PASSWORD,
} from "./actionTypes";
import {
  profileSuccess,
  profileError,
  getProfileSuccess,
  getProfileError,
  updateProfileImageSuccess,
  updateProfileImageError,
  updateProfilePassword,
  updateProfilePasswordSuccess,
  updateProfilePasswordError,
} from "./actions";

//Include Both Helper File with needed methods

import {
  getCurrentUser,
  updateCognitoPassword,
  updateUserAttributes,
} from "../../../helpers/aws_cognito_helper";

function* getCurrentUserInfo() {
  try {
    const response = yield call(getCurrentUser);
    yield put(getProfileSuccess(response));
  } catch (error) {
    yield put(getProfileError(error));
  }
}

function* editProfile({ payload: { user, attributes } }) {
  try {
    const response = yield call(updateUserAttributes, user, attributes);
    yield put(profileSuccess(response));
  } catch (error) {
    yield put(profileError(error));
  }
}
function* updateProfileImage({ payload: { user, image } }) {
  let attributes = user.attributes;
  attributes.picture = image;
  try {
    const response = yield call(updateUserAttributes, user, attributes);
    yield put(updateProfileImageSuccess(response));
  } catch (error) {
    yield put(updateProfileImageError(error));
  }
}
function* updatePassword({ payload: { user, password, newPassword } }) {
  try {
    const response = yield call(
      updateCognitoPassword,
      user,
      password,
      newPassword
    );
    yield put(updateProfilePasswordSuccess(response));
  } catch (error) {
    yield put(updateProfilePasswordError(error));
  }
}

export function* watchProfile() {
  yield takeEvery(GET_PROFILE, getCurrentUserInfo);
  yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(UPDATE_PROFILE_IMAGE, updateProfileImage);
  yield takeEvery(UPDATE_PROFILE_PASSWORD, updatePassword);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
