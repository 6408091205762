import React, {useState} from 'react'
import {Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {editRequest, getRequests} from "../../../store/requests/actions";
import {NotificationManager} from "react-notifications";
import {useDispatch} from "react-redux";
import {isLatitude, isLongitude} from "../../../helpers/common_functions_helper";

const EditRequest = (props) =>{
    const {editedRequest,setEditedRequest, modal,setModal} = props
    const [checkLat, setCheckLat] = useState(false);
    const [checkLng, setCheckLng] = useState(false);
    const dispatch = useDispatch();
    const statusTypes = [
        { key: "requested", value: "Requested" },
        { key: "accepted", value: "Accepted" },
        { key: "rejected", value: "Rejected" },
    ];
    const toggle = () => {
        if(modal){
            setEditedRequest(null)
        }
        setModal(!modal);
    };
    const getValueByKey = (status) => {
        const filtered = statusTypes.filter((poiType) => poiType.key === status)[0];
        return filtered.value;
    };
    const createOptions = () => {
        let component = [];
        statusTypes
            .sort((a, b) => a.value.localeCompare(b.value))
            .forEach((type, index) => {
                component.push(<option key={index}>{type.value}</option>);
            });
        return component;
    };
    const handleEditSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        setCheckLat(isLatitude(data.get("lat")));
        setCheckLng(isLongitude(data.get("lng")));
        const object = {
            id: editedRequest.id,
            requestFrom: editedRequest.requestFrom,
            location: {
                lat: data.get("lat"),
                lng: data.get("lng"),
            },
            subscriptionId: editedRequest.subscriptionId,
            oneSignalId: editedRequest.oneSignalId,
            status: statusTypes.filter((type) => type.value === data.get("type"))[0][
                "key"
                ],
        };
        dispatch(editRequest(object));
        dispatch(getRequests());
        toggle();
        NotificationManager.success("Request Updated!");
    };
    return(
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
                Edit Request
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleEditSubmit}>
                    <FormGroup>
                        <div className="inner-repeater mb-4">
                            <div className="inner form-group mb-0 row">
                                <Label className="col-form-label col-lg-2">
                                    Coordinates
                                </Label>
                                <div
                                    className="inner col-lg-10 ml-md-auto"
                                    id="repeater"
                                >
                                    <div
                                        id={"nested"}
                                        className="mb-3 row align-items-center"
                                    >
                                        <Col md="6">
                                            <Input
                                                id="lat"
                                                name="lat"
                                                type="number"
                                                min="0.000000000000000"
                                                step="0.0000000000000001"
                                                max="90.000000000000000"
                                                presicion={8}
                                                className="inner form-control"
                                                placeholder="Enter Latitude..."
                                                valid={checkLat}
                                                defaultValue={editedRequest.location.lat}
                                            />
                                            {checkLat === true && (
                                                <FormFeedback valid>
                                                    Valid Coordinate
                                                </FormFeedback>
                                            )}
                                        </Col>
                                        <Col md="6">
                                            <Input
                                                id="lng"
                                                name="lng"
                                                type="number"
                                                min="0.000000000000000"
                                                step="0.0000000000000001"
                                                max="90.000000000000000"
                                                presicion={8}
                                                className="inner form-control"
                                                placeholder="Enter Longitude..."
                                                valid={checkLng}
                                                defaultValue={editedRequest.location.lng}
                                            />
                                            {checkLng === true && (
                                                <FormFeedback valid>
                                                    Valid Coordinate
                                                </FormFeedback>
                                            )}
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup row>
                        <Row>
                            <label
                                className="col-form-label col-lg-2"
                            >
                                Status
                            </label>
                            <div className="col-md-10">
                                <Input
                                    id="type"
                                    name="type"
                                    type="select"
                                    defaultValue={getValueByKey(editedRequest.status)}
                                >
                                    {createOptions()}
                                </Input>
                            </div>
                        </Row>
                    </FormGroup>
                    <Row className="justify-content-end">
                        <Col lg="10">
                            <Button type="submit" color="primary">
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )


}
export default EditRequest;
