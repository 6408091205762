import {
  VERIFICATION_USER,
  VERIFICATION_USER_SUCCESSFUL,
  VERIFICATION_USER_FAILED,
} from "./actionTypes";

const initialState = {
  verificationSuccessMsg: null,
  verificationError: null,
};

const verificationUser = (state = initialState, action) => {
  switch (action.type) {
    case VERIFICATION_USER:
      state = {
        ...state,
        verificationSuccessMsg: null,
        verificationError: null,
      };
      break;
    case VERIFICATION_USER_SUCCESSFUL:
      state = {
        ...state,
        verificationSuccessMsg: action.payload,
      };
      break;
    case VERIFICATION_USER_FAILED:
      state = { ...state, verificationError: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default verificationUser;
