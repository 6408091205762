import { call, put, takeEvery } from "redux-saga/effects";
import {
    getMostPoisFail,
    getMostPoisSuccess, getMostUsersFail,
    getMostUsersSuccess
} from "./actions";
import {getMostPoiList, getMostUserList} from "../../helpers/backend_helper";
import {GET_MOST_POIS, GET_MOST_USERS} from "./actionTypes";

function* fetchMostPois(){
    try{
        const response = yield call(getMostPoiList)
        yield put(getMostPoisSuccess(response))
    }catch (error){
        yield put(getMostPoisFail(error))
    }
}

function* fetchMostUsers(){
    try{
        const response = yield call(getMostUserList)
        yield put(getMostUsersSuccess(response))
    }catch (error){
        yield put(getMostUsersFail(error))
    }
}

function* eventsSaga(){
    yield takeEvery(GET_MOST_POIS, fetchMostPois)
    yield takeEvery(GET_MOST_USERS, fetchMostUsers)
}
export default eventsSaga;
