import React, {useEffect, useState} from 'react'
import {Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {deUmlaut, isLatitude, isLongitude} from "../../../helpers/common_functions_helper";
import {NotificationManager} from "react-notifications";
import {poiTypes} from "../../../types/PoiTypes";
import {useDispatch, useSelector} from "react-redux";
import {getImageUrl, postImage} from "../../../helpers/backend_helper";
import {getAudios} from "../../../store/audio/actions";
import {addPoi} from "../../../store/pois/actions";


const CreatePoiFromRequest = (props) =>{
    const {createdRequest, modal,setModal,setCreatedRequest} = props;
    const [checkLat, setCheckLat] = useState(false);
    const [checkLng, setCheckLng] = useState(false);
    const [currentImage, setCurrentImage] = useState("");
    const [selects, setSelects] = useState([]);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAudios())
    }, []);
    const toggle = () => {
        if(modal){
            setCreatedRequest(null)
        }
        setModal(!modal);
    };
    const { audios } = useSelector((state) => ({
        audios: state.audios.list.sort((a,b) => a.name.localeCompare(b.name)),
    }));

    const handleAudioChange = (index, value) => {
        const updatedSelects = [...selects];
        updatedSelects[index].audio = value;
    };

    const createAudios = (point, index) => {
        let component = [];
        if (audios && audios.length > 0) {
            audios.forEach((audio) => {
                component.push(<option value={audio.id}>{audio.name}</option>);
            });
        }
        return (
            <select
                className="form-control"
                value={point.audio}
                onChange={(e) => handleAudioChange(index, e.target.value)}
            >
                {component}
            </select>
        );
    };
    const createPOIOptions = () => {
        let component = [];
        poiTypes
            .sort((a, b) => a.value.localeCompare(b.value))
            .forEach((type, index) => {
                component.push(<option key={index}>{type.value}</option>);
            });
        return component;
    };
    const handleDeleteSelect = (index) => {
        const updatedSelects = [...selects];
        updatedSelects.splice(index, 1);
        setSelects(updatedSelects);
    };

    const handleTriggerPointLat = (index, value) => {
        const updatedSelects = [...selects];
        updatedSelects[index].coordinate.lat = value;
        setSelects(updatedSelects);
    };
    const handleTriggerPointLng = (index, value) => {
        const updatedSelects = [...selects];
        updatedSelects[index].coordinate.lng = value;
        setSelects(updatedSelects);
    };
    const handleAddSelect = () => {
        setSelects([
            ...selects,
            { coordinate: { lat: Number(createdRequest.location.lat).toFixed(7),
                    lng: Number(createdRequest.location.lng).toFixed(7) }, audio: audios[0].id },
        ]);
    };

    const handlePOISubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        setCheckLat(isLatitude(data.get("lat")));
        setCheckLng(isLongitude(data.get("lng")));

        if (isLatitude(data.get("lat")) && isLongitude(data.get("lng"))) {
            NotificationManager.info("Creation Started!");
            if (data.get("image").name !== "") {
                getImageUrl(deUmlaut(data.get("image").name),"pois").then((imageResponse) =>{
                    if(imageResponse.status === 200){
                        const url = imageResponse.data.url;
                        postImage(url,data.get("image")).then((postImageResponse) =>{
                            if(postImageResponse.status === 200){
                                const object = {
                                    name: data.get("poiName").charAt(0).toUpperCase() + data.get("poiName").slice(1),
                                    description: data.get("description"),
                                    image: new URL("https://locco-content-bucket231847-staging.s3.eu-central-1.amazonaws.com/public/images/pois/"+ deUmlaut(data.get("image").name).toString()),
                                    createdAt: new Date().toLocaleDateString(),
                                    location: {
                                        lat: data.get("lat"),
                                        lng: data.get("lng"),
                                    },
                                    type: poiTypes.filter((type) => type.value === data.get("type"))[0]["key"],
                                    notificationTitle: data.get("notificationTitle"),
                                    triggerPoints: selects,
                                };
                                dispatch(addPoi(object));
                                NotificationManager.success("POI Created!");
                            }
                        })
                    }
                })
            } else {
                const object = {
                    name: data.get("poiName").charAt(0).toUpperCase() + data.get("poiName").slice(1),
                    description: data.get("description"),
                    image: null,
                    createdAt: new Date().toLocaleDateString(),
                    location: {
                        lat: data.get("lat"),
                        lng: data.get("lng"),
                    },
                    type: poiTypes.filter((type) => type.value === data.get("type"))[0][
                        "key"
                        ],
                    notificationTitle: data.get("notificationTitle"),
                    triggerPoints: selects,
                };
                dispatch(addPoi(object));
                NotificationManager.success("POI Created!");
            }
        }
        else {
            NotificationManager.error("Please Check Input Values!");
        }
    };

    return(
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
                Create POI From Request
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handlePOISubmit}>
                    <FormGroup className="mb-4" row>
                        <Label
                            htmlFor="poiName"
                            className="col-form-label col-lg-2"
                        >
                            Poi Name
                        </Label>
                        <Col lg="10">
                            <Input
                                id="poiName"
                                name="poiName"
                                type="text"
                                className="form-control"
                                placeholder="Enter Poi Name..."
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                            Poi Description
                        </Label>
                        <Col lg="10">
                            <Input
                                id="description"
                                name="description"
                                type="textarea"
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">Poi Image</Label>
                        <Col lg="10">
                            <Row>
                                <Col md={12} className="pr-0">
                                    <div className="mt-12 mt-md-0">
                                        <Input
                                            id="image"
                                            name="image"
                                            type="file"
                                            accept="image/*"
                                            className="form-control mb-3"
                                            onChange={(event) =>
                                                setCurrentImage(
                                                    URL.createObjectURL(event.target.files[0])
                                                )
                                            }
                                        />
                                        {currentImage && (
                                            <img
                                                width={64}
                                                height={64}
                                                src={currentImage}
                                                alt="poi"
                                            />
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <div className="inner-repeater mb-4">
                            <div className="inner form-group mb-0 row">
                                <Label className="col-form-label col-lg-2">
                                    Coordinates
                                </Label>
                                <div
                                    className="inner col-lg-10 ml-md-auto"
                                    id="repeater"
                                >
                                    <div
                                        id={"nested"}
                                        className="mb-3 row align-items-center"
                                    >
                                        <Col md="6">
                                            <Input
                                                id="lat"
                                                name="lat"
                                                type="number"
                                                min="0.000000"
                                                step="0.0000001"
                                                max="90.000000"
                                                presicion={8}
                                                className="inner form-control"
                                                placeholder="Enter Latitude..."
                                                valid={checkLat}

                                            />
                                        </Col>
                                        <Col md="6">
                                            <Input
                                                id="lng"
                                                name="lng"
                                                type="number"
                                                min="0.000000"
                                                step="0.0000001"
                                                max="90.000000"
                                                presicion={8}
                                                className="inner form-control"
                                                placeholder="Enter Longitude..."
                                                valid={checkLng}
                                            />
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                        <Label
                            htmlFor="notificationTitle"
                            className="col-form-label col-lg-2"
                        >
                            Notification Title
                        </Label>
                        <Col lg="10">
                            <Input
                                id="notificationTitle"
                                name="notificationTitle"
                                type="text"
                                className="form-control"
                                placeholder="Notification Title"
                            />
                        </Col>
                    </FormGroup>
                    <div className="inner form-group mb-0 row">
                        <Label className="col-form-label col-lg-2">
                            Trigger Points
                        </Label>
                        <div className="inner col-lg-10 ml-md-auto">
                            <div className="mb-3 row align-items-center">
                                {selects.map((point, index) => (
                                    <Row className="mt-1" key={index}>
                                        <div
                                            className="inner col-lg-10 ml-md-auto"
                                            id="repeater"
                                            key={index}
                                        >
                                            <div
                                                id={"nested"}
                                                className="mb-3 row align-items-center"
                                            >
                                                <Col md="12" key={index}>
                                                    <Input
                                                        id="lat"
                                                        name="lat"
                                                        type="number"
                                                        min="0.000000"
                                                        step="0.0000001"
                                                        max="90.000000"
                                                        presicion={8}
                                                        onChange={(event) =>
                                                            handleTriggerPointLat(
                                                                index,
                                                                event.target.value
                                                            )
                                                        }
                                                        defaultValue={Number(createdRequest.location.lat).toFixed(7)}
                                                        className="inner form-control"
                                                        placeholder="Enter Latitude..."
                                                    />
                                                    <FormFeedback valid>
                                                        Valid Coordinate
                                                    </FormFeedback>
                                                </Col>
                                                <Col md="12">
                                                    <Input
                                                        id="lng"
                                                        name="lng"
                                                        type="number"
                                                        min="0.000000"
                                                        step="0.0000001"
                                                        max="90.000000"
                                                        presicion={8}
                                                        defaultValue={Number(createdRequest.location.lng).toFixed(7)}
                                                        onChange={(event) =>
                                                            handleTriggerPointLng(
                                                                index,
                                                                event.target.value
                                                            )
                                                        }
                                                        className="inner form-control"
                                                        placeholder="Enter Longitude..."
                                                    />
                                                    <FormFeedback valid>
                                                        Valid Coordinate
                                                    </FormFeedback>
                                                </Col>
                                                <Col md="12">{createAudios(point, index)}</Col>
                                            </div>
                                        </div>
                                        <Col md="2">
                                            <Button
                                                color="danger"
                                                onClick={() => handleDeleteSelect(index)}
                                            >
                                                -
                                            </Button>
                                            <Button onClick={handleAddSelect} color="success">
                                                +
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}
                                {selects.length === 0 && (
                                    <Row>
                                        <Col md="12">
                                            <Button
                                                onClick={handleAddSelect}
                                                className="m-2"
                                                color="success"
                                            >
                                                Add Trigger Points
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        </div>
                    </div>

                    <FormGroup className="mb-4" row>
                        <Row className="mb-3">
                            <label
                                htmlFor="taskbudget"
                                className="col-form-label col-lg-2"
                            >
                                Type
                            </label>
                            <div className="col-md-10">
                                <Input id="type" name="type" type="select">
                                    {createPOIOptions()}
                                </Input>
                            </div>
                        </Row>
                    </FormGroup>
                    <Row className="justify-content-end">
                        <Col lg="10">
                            <Button type="submit" color="primary">
                                Create POI
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )

}
export default CreatePoiFromRequest
