import React from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

const SocialSource = (props) => {
  const {cognito,social} = props;
  const apple = social.filter((data) => data.login_type === "apple")
  const google = social.filter((data) => data.login_type === "google")
  const socials = [
    {
      title: "Email",
      bgColor: "bg-info",
      iconClass: "mdi-mail",
      description: cognito.length,
    },
    {
      title: "Apple",
      bgColor: "bg-secondary",
      iconClass: "mdi-apple",
      description: apple.length,
    },
    {
      title: "Google",
      bgColor: "bg-pink",
      iconClass: "mdi-google",
      description: google.length,
    },
    {
      title: "Social",
      bgColor: "bg-primary",
      iconClass: "mdi-account",
      description: (social.length) - (google.length + apple.length)
    }
  ];

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Registration Sources</CardTitle>
          <div className="text-center">
            <div className="avatar-sm mx-auto mb-4">
              <span className="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                <i className="mdi mdi-email text-primary"></i>
              </span>
            </div>
            <p className="font-16 text-muted mb-2"></p>
            <h5>
              <span className="text-dark">
                Total -{" "}
                <span className="text-muted font-16">{cognito.length + social.length} Registrations</span>{" "}
              </span>
            </h5>
          </div>
          <Row className="mt-4">
            {socials.map((social, key) => (
              <Col className="col-3" key={"_li_" + key}>
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3">
                    <span
                      className={
                        "avatar-title rounded-circle " +
                        social.bgColor +
                        " font-size-16"
                      }
                    >
                      <i
                        className={"mdi " + social.iconClass + " text-white"}
                      ></i>
                    </span>
                  </div>
                  <h5 className="font-size-15">{social.title}</h5>
                  <p className="text-muted mb-0">
                    {social.description} registrations
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SocialSource;
