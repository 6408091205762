import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_USER } from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./actions";
import { registerAmplifyUser } from "../../../helpers/aws_cognito_helper";

// initialize relavant method of both Auth

// Is user register successful then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      registerAmplifyUser,
      user.email,
      user.password,
      user.name,
      user.lastname
    );
    yield put(registerUserSuccessful(response));
    history("/verification");
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
