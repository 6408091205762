import {
    GET_MOST_POIS,
    GET_MOST_POIS_ERROR,
    GET_MOST_POIS_SUCCESS,
    GET_MOST_USERS, GET_MOST_USERS_ERROR,
    GET_MOST_USERS_SUCCESS
} from "./actionTypes";

export const getMostPois = () => {
    return {
        type: GET_MOST_POIS,
    };
};
export const getMostPoisSuccess = (mostPois) => {
    return {
        type: GET_MOST_POIS_SUCCESS,
        payload: mostPois
    };
};
export const getMostPoisFail = (error) => {
    return {
        type: GET_MOST_POIS_ERROR,
        payload: error,
    };
};

export const getMostUsers = () => {
    return {
        type: GET_MOST_USERS,
    };
};
export const getMostUsersSuccess = (mostUsers) => {
    return {
        type: GET_MOST_USERS_SUCCESS,
        payload: mostUsers
    };
};
export const getMostUsersFail = (error) => {
    return {
        type: GET_MOST_USERS_ERROR,
        payload: error,
    };
};
