import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { isEmpty } from "lodash";

import { Button, Card, CardBody } from "reactstrap";
import { getOrders as onGetOrders } from "store/actions";

// import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal";
// import { latestTransaction } from "../../common/data/dashboard";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./LatestTranactionCol";

import TableContainer from "../../components/Common/TableContainer";

const LatestTranaction = (props) => {
  const [modal1, setModal1] = useState(false);

  const toggleViewModal = () => setModal1(!modal1);

  const columns = useMemo(
    () => [
      {
        Header: "User ID",
        accessor: "orderId",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <OrderId {...cellProps} />;
        },
      },
      {
        Header: "User Name",
        accessor: "billingName",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Last Active Date",
        accessor: "orderdate",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Total Listened Audios",
        accessor: "total",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        },
      },
      // {
      //   Header: "Payment Status",
      //   accessor: "paymentStatus",
      //   disableFilters: true,
      //   filterable: false,
      //   Cell: cellProps => {
      //     return <PaymentStatus {...cellProps} />;
      //   },
      // },
      // {
      //   Header: "Payment Method",
      //   accessor: "paymentMethod",
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     return <PaymentMethod {...cellProps} />;
      //   },
      // },
      // {
      //   Header: "View Details",
      //   disableFilters: true,
      //   accessor: "view",
      //   Cell: cellProps => {
      //     return (
      //       <Button
      //         type="button"
      //         color="primary"
      //         className="btn-sm btn-rounded"
      //         onClick={toggleViewModal}
      //       >
      //         View Details
      //       </Button>
      //     );
      //   },
      // },
    ],
    []
  );

  return (
    <React.Fragment>
      {/*<EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />*/}
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Best Users</div>
          {/*<TableContainer*/}
          {/*  columns={columns}*/}
          {/*  data={latestTransaction}*/}
          {/*  isGlobalFilter={false}*/}
          {/*  isAddOptions={false}*/}
          {/*  customPageSize={6}*/}
          {/*/>*/}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

LatestTranaction.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(LatestTranaction);
