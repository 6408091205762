import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import {
  loginAmplifyUser,
  logoutAmplifyUser,
} from "../../../helpers/aws_cognito_helper";

function* loginUser({ payload: { user, navigate } }) {
  try {
    const response = yield call(loginAmplifyUser, user.email, user.password);
    localStorage.setItem(
      "authUser",
      response.signInUserSession.accessToken.jwtToken
    );
    yield put(loginSuccess(response));
    navigate("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    yield call(logoutAmplifyUser());
    yield put(logoutUserSuccess());
    history("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { type, history } }) {
  //TODO: Cognito
  // try {
  //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //     const fireBaseBackend = getFirebaseBackend();
  //     const response = yield call(fireBaseBackend.socialLoginUser, type);
  //     if (response) {
  //       history("/dashboard");
  //     } else {
  //       history("/login");
  //     }
  //     localStorage.setItem("authUser", JSON.stringify(response));
  //     yield put(loginSuccess(response));
  //   }
  //   else {
  //     const response = yield call(postSocialLogin, data);
  //     localStorage.setItem("authUser", JSON.stringify(response));
  //     yield put(loginSuccess(response));
  //   }
  //   history("/dashboard");
  // } catch (error) {
  //   yield put(apiError(error));
  // }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
