import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Verification from "../pages/Authentication/Verification";

// Pages Calendar
import AudioCreate from "../pages/Audios/audio-create";
import AudioGrid from "../pages/Audios/audio-grid";
import UserList from "../pages/Users/users-list";
import PoiList from "../pages/Pois/poi-list";
import PoiCreate from "../pages/Pois/poi-create";
import RouteList from "../pages/Routes/route-list";
import RouteCreate from "../pages/Routes/route-create";
import RequestList from "../pages/Requests/request-list";
import RequestCreate from "../pages/Requests/request-create";
import PoiOverview from "../pages/Pois/poi-overview";
import Dashboard from "../pages/Dashboard";
import RequestOverview from "../pages/Requests/request-overview";
import UserProfile from "../pages/Profile/user-profile";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/audio/create", component: <AudioCreate /> },
  { path: "/audio/list", component: <AudioGrid /> },
  { path: "/profile", component: <UserProfile/>},


  { path: "/user/list", component: <UserList /> },

  { path: "/poi/list", component: <PoiList /> },
  { path: "/poi/overview", component: <PoiOverview /> },
  { path: "/poi/create", component: <PoiCreate /> },

  { path: "/route/list", component: <RouteList /> },
  { path: "/route/create", component: <RouteCreate /> },

  { path: "/request/list", component: <RequestList /> },
  { path: "/request/overview", component: <RequestOverview/>},
  { path: "/request/create", component: <RequestCreate /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/verification", component: <Verification /> },
];

export { authProtectedRoutes, publicRoutes };
