import {
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_SOCIAL_USERS,
  GET_SOCIAL_USERS_SUCCESS,
  GET_SOCIAL_USERS_FAIL,
  DELETE_SOCIAL_USER,
  DELETE_SOCIAL_USER_SUCCESS, DELETE_SOCIAL_USER_FAIL,
} from "./actionTypes";

export const getUsers = () => ({
  type: GET_USERS,
});

export const getUsersSuccess = (users) => ({
  type: GET_USERS_SUCCESS,
  payload: users,
});

export const getSocialUsers = () =>({
  type: GET_SOCIAL_USERS
})

export const getSocialUsersSuccess= (socialUsers) => ({
  type: GET_SOCIAL_USERS_SUCCESS,
  payload: socialUsers
})

export const getSocialUsersFail = (error) =>({
  type: GET_SOCIAL_USERS_FAIL
})

export const addNewUser = (user) => ({
  type: ADD_NEW_USER,
  payload: user,
});

export const addUserSuccess = (user) => ({
  type: ADD_USER_SUCCESS,
  payload: user,
});

export const addUserFail = (error) => ({
  type: ADD_USER_FAIL,
  payload: error,
});

export const getUsersFail = (error) => ({
  type: GET_USERS_FAIL,
  payload: error,
});

export const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: user,
});

export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});

export const updateUserFail = (error) => ({
  type: UPDATE_USER_FAIL,
  payload: error,
});

export const deleteUser = (user) => ({
  type: DELETE_USER,
  payload: user,
});

export const deleteUserSuccess = (user) => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
});

export const deleteUserFail = (error) => ({
  type: DELETE_USER_FAIL,
  payload: error,
});

export const deleteSocialUser = (user) => ({
  type: DELETE_SOCIAL_USER,
  payload: user,
});

export const deleteSocialUserSuccess = (user) => ({
  type: DELETE_SOCIAL_USER_SUCCESS,
  payload: user,
});

export const deleteSocialUserFail = (error) => ({
  type: DELETE_SOCIAL_USER_FAIL,
  payload: error,
});
