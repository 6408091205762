import React, {useEffect, useState} from "react";

import {Container} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {useDispatch, useSelector} from "react-redux";
import {Map, Marker, Popup, TileLayer} from "react-leaflet";
import useWindowDimensions from "../../helpers/common_functions_helper";
import {getRequests} from "../../store/requests/actions";

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
const blueIcon = L.icon({
    iconUrl: require("../../assets/images/icons/marker-blue.png"),
    iconSize:     [32, 32], // size of the icon
})
const RequestOverview = () =>{
    const [routes, setRoutes] = useState([])
    const dispatch = useDispatch();
    const { requests } = useSelector((state) => ({
        requests: state.requests.list,
    }));
    const { height, width } = useWindowDimensions();
    useEffect(() => {
        dispatch(getRequests());
    }, []);

    useEffect(() => {
        let data = []
        if (requests && requests.length > 0) {
            requests.forEach((request) => {
                if(request.location.lat && request.location.lng){
                    data.push({
                        request: {
                            name: request.requestFrom,
                            lat: request.location.lat,
                            lng: request.location.lng
                        }
                    })
                }
            })
            setRoutes(data)
        }

    }, [requests])

    const createMarkers = () => {
        let component = [];
        if (routes && routes) {
            routes.forEach((route, index) => {
                component.push(
                    <Marker icon={blueIcon} key={index} position={[route.request.lat, route.request.lng]}>
                        <Popup>{route.request.name}</Popup>
                    </Marker>
                );
            });
        }
        return component;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Request" breadcrumbItem="Overview"/>
                    <Map
                        center={[48.13743,11.57549]}
                        zoom={10}
                        style={{height: height,width:width}}
                    >
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {routes && createMarkers()}
                    </Map>

                </Container>
            </div>
        </React.Fragment>
    )

}
export default RequestOverview
