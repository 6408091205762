import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import user1 from "../../assets/images/users/avatar.png";
// actions
import {
  getProfile,
  updateProfileImage,
  updateProfileImageSuccess,
  updateProfilePassword,
} from "../../store/actions";
import { NotificationManager } from "react-notifications";
import { getImageUrl, postImage } from "../../helpers/backend_helper";

const UserProfile = () => {
  //meta title
  document.title = "Profile | Locco Content Management";
  const ref = useRef();
  const handleClick = (e) => {
    ref.current.click();
  };
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.Profile.currentUser,
  }));

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    getImageUrl(fileUploaded.name, "users").then((imageResponse) => {
      NotificationManager.info("Uploading Started!");
      if (imageResponse.status === 200) {
        const url = imageResponse.data.url;
        postImage(url, fileUploaded).then((postImageResponse) => {
          if (postImageResponse.status === 200) {
            const newUrl =
              "https://locco-content-bucket231847-staging.s3.eu-central-1.amazonaws.com/public/images/users/" +
              fileUploaded.name;
            dispatch(updateProfileImage(currentUser, newUrl));
            NotificationManager.success("Image Uploaded");
          } else {
            NotificationManager.error("Upload Failed");
          }
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const validation = useFormik({
    initialValues: {
      password: "",
      updatePassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
      updatePassword: Yup.string().required("Please Enter New Password"),
      confirmPassword: Yup.string().required(
        "Please Confirm your New Password"
      ),
    }),
    onSubmit: (values) => {
      if (currentUser) {
        dispatch(
          updateProfilePassword(
            currentUser,
            values.password,
            values.updatePassword
          )
        );
        NotificationManager.success("Password Updated");
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="LOCCO" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <Row>
                        {currentUser &&
                          currentUser.attributes &&
                            currentUser.attributes.picture !== "\"\"" && (
                            <Row>
                              <img
                                style={{ width: 100, height: 75 }}
                                src={currentUser.attributes.picture}
                                alt="Profile Picture"
                              />
                            </Row>
                          )}
                        {(!currentUser ||
                          (currentUser && !currentUser.attributes) ||
                          (currentUser &&
                            currentUser.attributes &&
                            currentUser.attributes.picture === "\"\"")) && (
                          <Row>
                            <img
                              style={{ width: 100, height: 75 }}
                              src={user1}
                              alt="Profile Picture"
                            />
                          </Row>
                        )}
                        <Row>
                          <Button
                            onClick={handleClick}
                            style={{
                              borderRadius: "50%",
                              width: 35,
                              height: 35,
                              alignItems: "center",
                              marginTop: -25,
                              marginLeft: 65,
                            }}
                            color="primary"
                          >
                            <i className="bx bx-upload"></i>
                          </Button>
                          <input
                            onChange={handleChange}
                            ref={ref}
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                        </Row>
                      </Row>
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        {currentUser && currentUser.attributes && (
                          <div>
                            <h5>
                              {currentUser.attributes.given_name}{" "}
                              {currentUser.attributes.name}
                            </h5>
                            <p className="mb-1">
                              {currentUser.attributes.email}
                            </p>
                            <p className="mb-0">
                              Id: {currentUser.attributes.sub}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change Password</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">Password</Label>
                  <Input
                    name="password"
                    className="form-control"
                    autoComplete="password"
                    placeholder="Enter Current Password"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={
                      !!(
                        validation.touched.password &&
                        validation.errors.password
                      )
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="form-group">
                  <Label className="form-label">New Password</Label>
                  <Input
                    name="updatePassword"
                    className="form-control"
                    autoComplete="new-password"
                    placeholder="New Password"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.updatePassword || ""}
                    invalid={
                      !!(
                        validation.touched.updatePassword &&
                        validation.errors.updatePassword
                      )
                    }
                  />
                  {validation.touched.updatePassword &&
                  validation.errors.updatePassword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.updatePassword}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="form-group">
                  <Label className="form-label">Password</Label>
                  <Input
                    name="confirmPassword"
                    className="form-control"
                    autoComplete="confirm-password"
                    placeholder="Confirm Password"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.confirmPassword || ""}
                    invalid={
                      !!(
                        validation.touched.confirmPassword &&
                        validation.errors.confirmPassword
                      )
                    }
                  />
                  {validation.touched.confirmPassword &&
                  validation.errors.confirmPassword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.confirmPassword}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Password Requirements</Label>
                  <ul>
                    <li>Password minimum length : 8 character</li>
                    <li>Contains at least 1 number</li>
                    <li>Contains at least 1 special character</li>
                    <li>Contains at least 1 uppercase letter</li>
                    <li>Contains at least 1 lowercase letter </li>
                  </ul>
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="success">
                    Update
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
