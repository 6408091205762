export const GET_ROUTES = "GET_ROUTES";
export const GET_ROUTES_SUCCESS = "GET_ROUTES_SUCCESS";

export const GET_ROUTES_ERROR = "GET_ROUTES_ERROR";

export const ADD_ROUTE = "ADD_ROUTE";
export const ADD_ROUTE_SUCCESS = "ADD_ROUTE_SUCCESS";
export const ADD_ROUTE_ERROR = "ADD_ROUTE_ERROR";

export const EDIT_ROUTE = "EDIT_ROUTE";
export const EDIT_ROUTE_SUCCESS = "EDIT_ROUTE_SUCCESS";
export const EDIT_ROUTE_ERROR = "EDIT_ROUTE_ERROR";

export const DELETE_ROUTE = "DELETE_ROUTE";
export const DELETE_ROUTE_SUCCESS = "DELETE_ROUTE_SUCCESS";
export const DELETE_ROUTE_ERROR = "DELETE_ROUTE_ERROR";
