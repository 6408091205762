export const GET_POIS = "GET_POIS";
export const GET_POIS_SUCCESS = "GET_POIS_SUCCESS";

export const GET_POIS_ERROR = "GET_POIS_ERROR";

export const ADD_POI = "ADD_POI";
export const ADD_POI_SUCCESS = "ADD_POI_SUCCESS";
export const ADD_POI_ERROR = "ADD_POI_ERROR";

export const EDIT_POI = "EDIT_POI";
export const EDIT_POI_SUCCESS = "EDIT_POI_SUCCESS";
export const EDIT_POI_ERROR = "EDIT_POI_ERROR";

export const DELETE_POI = "DELETE_POI";
export const DELETE_POI_SUCCESS = "DELETE_POI_SUCCESS";
export const DELETE_POI_ERROR = "DELETE_POI_ERROR";
