import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  UPDATE_PROFILE_IMAGE,
  UPDATE_PROFILE_IMAGE_SUCCESS,
  UPDATE_PROFILE_IMAGE_ERROR,
  UPDATE_PROFILE_PASSWORD,
  UPDATE_PROFILE_PASSWORD_ERROR,
  UPDATE_PROFILE_PASSWORD_SUCCESS,
} from "./actionTypes";

export const getProfile = () => {
  return {
    type: GET_PROFILE,
  };
};

export const getProfileSuccess = (profile) => ({
  type: GET_PROFILE_SUCCESS,
  payload: profile,
});

export const getProfileError = (error) => ({
  type: GET_PROFILE_ERROR,
  payload: error,
});

export const editProfile = (user) => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  };
};

export const profileSuccess = (msg) => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  };
};

export const profileError = (error) => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  };
};

export const resetProfileFlag = (error) => {
  return {
    type: RESET_PROFILE_FLAG,
  };
};

export const updateProfileImage = (user, image) => {
  return {
    type: UPDATE_PROFILE_IMAGE,
    payload: { user, image },
  };
};

export const updateProfileImageSuccess = (msg) => {
  return {
    type: UPDATE_PROFILE_IMAGE_SUCCESS,
    payload: msg,
  };
};

export const updateProfileImageError = (error) => {
  return {
    type: UPDATE_PROFILE_IMAGE_ERROR,
    payload: error,
  };
};

export const updateProfilePassword = (user, password, newPassword) => {
  return {
    type: UPDATE_PROFILE_PASSWORD,
    payload: { user, password, newPassword },
  };
};

export const updateProfilePasswordSuccess = (msg) => {
  return {
    type: UPDATE_PROFILE_PASSWORD_SUCCESS,
    payload: msg,
  };
};

export const updateProfilePasswordError = (error) => {
  return {
    type: UPDATE_PROFILE_PASSWORD_ERROR,
    payload: error,
  };
};
