import React, { useEffect, useState } from "react";
import {
  Container, Form, FormGroup, Input, Label, Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";

import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import Cards

import { useSelector, useDispatch } from "react-redux";
import AudioCard from "./audio-card";
import {deleteAudio, getAudios} from "../../store/audio/actions";
import DeleteModal from "../../components/Common/DeleteModal";
import {NotificationManager} from "react-notifications";
import AudioEdit from "./modals/audio-edit";

const AudioGrid = () => {
  //meta title
  document.title = "Audios";
  const [modal, setModal] = useState(false);
  const [editedAudio, setEditedAudio] = useState()
  const [deletedAudio, setDeletedAudio] = useState()
  const [deleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10)
  const [currentState, setCurrentState] = useState(0);
  const [pageState, setPageState] = useState(0);
  const { audios } = useSelector((state) => ({
    audios: state.audios.list,
  }));
  const [searchedName, setSearchedName] = useState()
  const [searchedAudios, setSearchedAudios] = useState(audios)
  useEffect(() => {
    setPageState(Math.ceil(searchedAudios.length / pageSize));
  }, [pageSize]);
  const handlePagination = (e, currentPage) => {
    e.preventDefault();
    setCurrentState(currentPage);
  };
  const handlePageSize = (value) =>{
    setPageState(Math.ceil(searchedAudios.length / value));
    setPageSize(value)
  }
  const handleSearchedItems = (value) =>{
    setSearchedName(value)
  }

  useEffect(() => {
    if(searchedName){
      let searchedAudios = audios.filter((audio) => audio.name.toLowerCase().includes(searchedName))
      setSearchedAudios(searchedAudios)
    }
    else{
      setSearchedAudios(audios)
    }
  }, [searchedName]);

  useEffect(() => {
    dispatch(getAudios());
  }, []);

  const createAudioCard = () =>{
    return(
        <AudioCard
            audios={searchedAudios.sort((a, b) => a.name > b.name ? 1 : -1)
                  .slice(currentState * pageSize, (currentState +1 )* pageSize)}
            setModalOpen={setModal}
            setDeleteModal={setDeleteModal}
            setEditedAudio={setEditedAudio}
            setDeletedAudio={setDeletedAudio}
        />
    )
  }


  const handleDeleteAudio = () => {
    NotificationManager.info("Deletion Started!");
    dispatch(deleteAudio(deletedAudio));
    setDeleteModal(false);
    dispatch(getAudios());
    NotificationManager.success("Deletion Completed!");
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Audios" breadcrumbItem="Audio List" />
          <Form>
            <FormGroup defaultValue={20} onChange={event => handlePageSize(event.target.value)}>
              <Label for="exampleSelect">
                Page Size
              </Label>
              <Input
                  id="exampleSelect"
                  name="select"
                  type="select"
              >
                <option>
                  10
                </option>
                <option>
                  20
                </option>
                <option>
                  30
                </option>
                <option>
                  40
                </option>
                <option>
                  50
                </option>
              </Input>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup onChange={event => handleSearchedItems(event.target.value)}>
              <Label for="exampleSearch">
                Search
              </Label>
              <Input
                  id="exampleSearch"
                  name="search"
                  placeholder="Name"
                  type="search"
              />
            </FormGroup>
          </Form>
            <DeleteModal
                  show={deleteModal}
                  onDeleteClick={handleDeleteAudio}
                  onCloseClick={() => setDeleteModal(false)}
                  title={"Are you sure you want to permanently delete the Audio?"}
              />

          <Row>
            {searchedAudios && searchedAudios.length > 0 && createAudioCard()}
          </Row>

          {
            editedAudio && <AudioEdit editedAudio={editedAudio}
                                      modal={modal}
                                      setModal={setModal}
                                      setEditedAudio={setEditedAudio}

              />
          }

          <Pagination size="md" aria-label="Page navigation">
            <PaginationItem disabled={currentState <= 0}>
              <PaginationLink previous />
            </PaginationItem>
            {[...Array(pageState)].map((page, i) => (
                <PaginationItem active={i === currentState} key={i}>
                  <PaginationLink onClick={e => handlePagination(e, i)} href="#">
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
            ))}
            <PaginationItem  disabled={currentState >= pageState - 1}>
              <PaginationLink next />
            </PaginationItem>
          </Pagination>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AudioGrid);
