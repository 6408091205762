import {
  GET_AUDIOS,
  GET_AUDIOS_SUCCESS,
  GET_AUDIOS_ERROR,
  ADD_AUDIO,
  ADD_AUDIO_SUCCESS,
  ADD_AUDIO_ERROR,
  DELETE_AUDIO_ERROR,
  DELETE_AUDIO_SUCCESS,
  DELETE_AUDIO,
  EDIT_AUDIO_ERROR,
  EDIT_AUDIO_SUCCESS,
  EDIT_AUDIO,
} from "./actionTypes";

export const getAudios = () => {
  return {
    type: GET_AUDIOS,
  };
};
export const getAudiosSuccess = (audios) => {
  return {
    type: GET_AUDIOS_SUCCESS,
    payload: audios,
  };
};
export const getAudiosFail = (error) => {
  return {
    type: GET_AUDIOS_ERROR,
    payload: error,
  };
};
export const addAudio = (audio) => {
  return {
    type: ADD_AUDIO,
    payload: audio,
  };
};
export const addAudioSuccess = (audio) => {
  return {
    type: ADD_AUDIO_SUCCESS,
    payload: audio,
  };
};
export const addAudioFail = (error) => {
  return {
    type: ADD_AUDIO_ERROR,
    payload: error,
  };
};
export const editAudio = (audio) => {
  return {
    type: EDIT_AUDIO,
    payload: audio,
  };
};
export const editAudioSuccess = (audio) => {
  return {
    type: EDIT_AUDIO_SUCCESS,
    payload: audio,
  };
};
export const editAudioFail = (error) => {
  return {
    type: EDIT_AUDIO_ERROR,
    payload: error,
  };
};

export const deleteAudio = (audio) => {
  return {
    type: DELETE_AUDIO,
    payload: audio,
  };
};
export const deleteAudioSuccess = (audio) => {
  return {
    type: DELETE_AUDIO_SUCCESS,
    payload: audio,
  };
};
export const deleteAudioFail = (error) => {
  return {
    type: DELETE_AUDIO_ERROR,
    payload: error,
  };
};
