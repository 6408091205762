/* USERS */
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";


export const GET_SOCIAL_USERS = "GET_SOCIAL_USERS"

export const GET_SOCIAL_USERS_SUCCESS = "GET_SOCIAL_USERS_SUCCESS";
export const GET_SOCIAL_USERS_FAIL = "GET_SOCIAL_USERS_FAIL"

export const ADD_NEW_USER = "ADD_NEW_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

/**
 * Edit user
 */
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

/**
 * Delete user
 */
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const DELETE_SOCIAL_USER = "DELETE_SOCIAL_USER";
export const DELETE_SOCIAL_USER_SUCCESS = "DELETE_SOCIAL_USER_SUCCESS";
export const DELETE_SOCIAL_USER_FAIL = "DELETE_SOCIAL_USER_FAIL";
