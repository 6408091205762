/* Wallet */
export const GET_WALLET = "GET_WALLET";
export const GET_WALLET_SUCCESS = "GET_WALLET_SUCCESS";
export const GET_WALLET_FAIL = "GET_WALLET_FAIL";

/* ORDERS */
export const GET_CRYPTO_ORDERS = "GET_CRYPTO_ORDERS";
export const GET_CRYPTO_ORDERS_SUCCESS = "GET_CRYPTO_ORDERS_SUCCESS";
export const GET_CRYPTO_ORDERS_FAIL = "GET_CRYPTO_ORDERS_FAIL";

/* PRODUCTS */
export const GET_CRYPTO_PRODUCTS = "GET_CRYPTO_PRODUCTS";
export const GET_CRYPTO_PRODUCTS_SUCCESS = "GET_CRYPTO_PRODUCTS_SUCCESS";
export const GET_CRYPTO_PRODUCTS_FAIL = "GET_CRYPTO_PRODUCTS_FAIL";
