export const GET_PROFILE = "GET_PROFILE";

export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";

export const EDIT_PROFILE = "EDIT_PROFILE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE";
export const UPDATE_PROFILE_IMAGE_SUCCESS = "UPDATE_PROFILE_IMAGE_SUCCESS";
export const UPDATE_PROFILE_IMAGE_ERROR = "UPDATE_PROFILE_IMAGE_ERROR";

export const UPDATE_PROFILE_PASSWORD = "UPDATE_PROFILE_PASSWORD";
export const UPDATE_PROFILE_PASSWORD_SUCCESS =
  "UPDATE_PROFILE_PASSWORD_SUCCESS";
export const UPDATE_PROFILE_PASSWORD_ERROR = "UPDATE_PROFILE_PASSWORD_ERROR";

export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";
