import React from "react";
import { Card, CardBody, CardTitle, Progress } from "reactstrap";

const TopCities = (props) => {
  const {mostPois} = props
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Most Listened POIs</CardTitle>
          <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-map-pin text-primary display-4" />
            </div>
            <h3>{mostPois && mostPois[0] && mostPois[0].count}</h3>
            <p>{mostPois && mostPois[0] && mostPois[0].data}</p>
          </div>

          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
              {
                mostPois.map((poi, key) =>(
                    <tr key={key}>
                      <td key={key} style={{ width: "30%" }}>
                        <p className="mb-o">{poi.data}</p>
                      </td>
                      <td style={{ width: "25%" }}>
                        <h5 className="mb-o">{poi.count}</h5>
                      </td>
                      <td>
                        <Progress
                            value={poi.count/10}
                            color="success"
                            className="bg-transparent progress-sm"
                            size="sm"
                        />
                      </td>
                    </tr>
                ))
              }
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default TopCities;
