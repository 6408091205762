import { getCurrentCredentials } from "./aws_cognito_helper";
import axios from "axios";
const BASE_URL =
  "https://n5vd2rg187.execute-api.eu-central-1.amazonaws.com/staging";

export const getCognitoUsers = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/users", {
        headers: {
          Authorization: getCurrentCredentials(),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getSocialUsersFromBackend = () =>{
  return new Promise((resolve, reject) => {
    axios
        .get(BASE_URL + "/users/social", {
          headers: {
            Authorization: getCurrentCredentials(),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
  });
}

export const updateCognitoUser = (user) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BASE_URL + "/users/" + user.id, user, {
        headers: {
          Authorization: getCurrentCredentials(),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const deleteCognitoUser = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(BASE_URL + "/users/" + id, {
        headers: {
          Authorization: getCurrentCredentials(),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const deleteSocialUserFromDatabase= (email) =>{
  return new Promise((resolve, reject) => {
    axios
        .delete(BASE_URL + "/users/social/" + email, {
          headers: {
            Authorization: getCurrentCredentials(),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
  });
}
export const createCognitoUser = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + "/users/", data, {
        headers: {
          Authorization: getCurrentCredentials(),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getAudioList = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/audios")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getRequestList = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/requests")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getMostPoiList = () =>{
  return new Promise((resolve, reject) =>{
    axios
        .get(BASE_URL+ "/events/most-pois")
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
  })
}
export const getMostUserList = () =>{
  return new Promise((resolve, reject) =>{
    axios
        .get(BASE_URL+ "/events/most-users")
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
  })
}

export const deleteRequest = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(BASE_URL + "/requests/" + request.id, {
        headers: {
          Authorization: getCurrentCredentials(),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postRequest = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + "/requests", request, {
        headers: {
          Authorization: getCurrentCredentials(),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateRequest = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BASE_URL + "/requests", request, {
        headers: {
          Authorization: getCurrentCredentials(),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getImageUrl = (imageName, type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        BASE_URL +
          "/audios/generate-image-url" +
          "?name=" +
          imageName +
          "&type=" +
          type
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAudioUrl = (audioName) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/audios/generate-audio-url" + "?name=" + audioName)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const postImage = (url, image) => {
  return new Promise((resolve, reject) => {
    axios
      .put(url, image)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postAudio = (url, audio) => {
  const formData = new FormData();
  formData.append("audioData", audio);
  return new Promise((resolve, reject) => {
    axios
      .put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateAudio = (audio) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BASE_URL + "/audios", audio, {
        headers: {
          Authorization: getCurrentCredentials(),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const deleteAudio = (audio) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(BASE_URL + "/audios/" + audio.id, {
        headers: {
          Authorization: getCurrentCredentials(),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postAudioInformation = (information) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + "/audios/info", information, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPoiList = () => {
  return new Promise((resolve, reject) => {
    getCurrentCredentials().then((credentials) => {
      axios
        .get(BASE_URL + "/pois", {
          headers: {
            Authorization: credentials,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
};
export const postPoi = (poi) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + "/pois", poi, {
        headers: {
          Authorization: getCurrentCredentials(),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updatePoi = (poi) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BASE_URL + "/pois", poi, {
        headers: {
          Authorization: getCurrentCredentials(),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const deletePoi = (poi) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(BASE_URL + "/pois/" + poi.id, {
        headers: {
          Authorization: getCurrentCredentials(),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getRouteList = () => {
  return new Promise((resolve, reject) => {
    getCurrentCredentials().then((credentials) => {
      axios
        .get(BASE_URL + "/routes", {
          headers: {
            Authorization: credentials,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
};
export const postRoute = (route) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + "/routes", route, {
        headers: {
          Authorization: getCurrentCredentials(),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateRoute = (route) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BASE_URL + "/routes", route, {
        headers: {
          Authorization: getCurrentCredentials(),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const deleteRoute = (route) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(BASE_URL + "/routes/" + route.id, {
        headers: {
          Authorization: getCurrentCredentials(),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
