import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { VERIFICATION_USER } from "./actionTypes";
import { userVerificationSuccess, userVerificationError } from "./actions";
import { verificationAmplifyUser } from "../../../helpers/aws_cognito_helper";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* verifyUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      verificationAmplifyUser,
      user.email,
      user.code.toString()
    );
    yield put(userVerificationSuccess(response));
    history("/login");
  } catch (error) {
    yield put(userVerificationError(error));
  }
}

export function* watchUserVerification() {
  yield takeEvery(VERIFICATION_USER, verifyUser);
}

function* verificationSaga() {
  yield all([fork(watchUserVerification)]);
}

export default verificationSaga;
