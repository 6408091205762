import { Amplify, Auth } from "aws-amplify";

export const registerAmplifyUser = (email, password, name, lastname) => {
  return new Promise((resolve, reject) => {
    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name: lastname,
        given_name: `${name} ${lastname}`,
        picture: null,
      },
    })
      .then((user) => {
        resolve(user);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const verificationAmplifyUser = (email, code) => {
  return new Promise((resolve, reject) => {
    Auth.confirmSignUp(email, code)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loginAmplifyUser = (email, password) => {
  return new Promise((resolve, reject) => {
    Auth.signIn(email, password)
      .then((user) => {
        resolve(user);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        resolve(user);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCurrentCredentials = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((session) => {
        resolve(session.getAccessToken().getJwtToken());
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateUserAttributes = (user, attributes) => {
  return new Promise((resolve, reject) => {
    Auth.updateUserAttributes(user, attributes)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCognitoPassword = (user, password, newPassword) => {
  return new Promise((resolve, reject) => {
    Auth.changePassword(user, password, newPassword)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgetPasswordAmplifyUser = (email) => {
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(email)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const logoutAmplifyUser = () => {
  return new Promise((resolve, reject) => {
    Auth.signOut()
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};
