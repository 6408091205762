import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// users
import user1 from "../../../assets/images/users/avatar.png";
import { getProfile } from "../../../store/auth/profile/actions";

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.Profile.currentUser,
  }));

  const [username, setUsername] = useState("");

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.name &&
      currentUser.attributes.given_name
    ) {
      setUsername(currentUser.attributes.given_name);
    }
  }, [currentUser]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {currentUser &&
            currentUser.attributes &&
            currentUser.attributes.picture && currentUser.attributes.picture !== "\"\"" && (
              <img
                style={{ width: 50, height: 50, padding: 3 }}
                className="rounded-circle"
                src={currentUser.attributes.picture}
                alt="Profile Picture"
              />
            )}
          {(!currentUser ||
            (currentUser && !currentUser.attributes) ||
            (currentUser &&
              currentUser.attributes &&
              currentUser.attributes.picture === "\"\"")) && (
            <img
              style={{ width: 50, height: 50, padding: 3 }}
              className="rounded-circle"
              src={user1}
              alt="Header Avatar"
            />
          )}

          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          {/*<DropdownItem tag="a" href="/crypto-wallet">*/}
          {/*  <i className="bx bx-wallet font-size-16 align-middle me-1" />*/}
          {/*  {props.t("My Wallet")}*/}
          {/*</DropdownItem>*/}
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem>
          {/*<DropdownItem tag="a" href="auth-lock-screen">*/}
          {/*  <i className="bx bx-lock-open font-size-16 align-middle me-1" />*/}
          {/*  {props.t("Lock screen")}*/}
          {/*</DropdownItem>*/}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
