import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar.png";
import profileImg from "../../assets/images/profile-img.png";
import { useSelector } from "react-redux";

const WelcomeComp = () => {
  const { currentUser } = useSelector((state) => ({
    currentUser: state.Profile.currentUser,
  }));

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>LOCCO Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                {currentUser &&
                    currentUser.attributes &&
                    currentUser.attributes.picture && currentUser.attributes.picture !== "\"\"" ? (
                        <img
                            style={{ width: 50, height: 50, padding: 3 }}
                            className="rounded-circle"
                            src={currentUser.attributes.picture}
                            alt="Profile Picture"
                        />
                    ) :<img
                    src={avatar1}
                    alt=""
                    className="img-thumbnail rounded-circle"
                /> }
              </div>
              {currentUser && currentUser.attributes && (
                <h5 className="font-size-14 text-truncate">
                  {currentUser.attributes.given_name +
                    " " +
                    currentUser.attributes.name}
                </h5>
              )}
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <div className="mt-4">
                  <Link to="/profile" className="btn btn-primary  btn-sm">
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default WelcomeComp;
