export const GET_REQUESTS = "GET_REQUESTS";
export const GET_REQUESTS_SUCCESS = "GET_REQUESTS_SUCCESS";

export const SET_REQUEST_FILTER = "SET_STATUS_FILTER"

export const GET_REQUESTS_ERROR = "GET_REQUESTS_ERROR";

export const ADD_REQUEST = "ADD_REQUEST";
export const ADD_REQUEST_SUCCESS = "ADD_REQUEST_SUCCESS";
export const ADD_REQUEST_ERROR = "ADD_REQUEST_ERROR";

export const EDIT_REQUEST = "EDIT_REQUEST";
export const EDIT_REQUEST_SUCCESS = "EDIT_REQUEST_SUCCESS";
export const EDIT_REQUEST_ERROR = "EDIT_REQUEST_ERROR";

export const DELETE_REQUEST = "DELETE_REQUEST";
export const DELETE_REQUEST_SUCCESS = "DELETE_REQUEST_SUCCESS";
export const DELETE_REQUEST_ERROR = "DELETE_REQUEST_ERROR";
