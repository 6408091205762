import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { getPois } from "../../store/pois/actions";
import { getAudios } from "../../store/audio/actions";
import { NotificationManager } from "react-notifications";
import { addRoute } from "../../store/routes/actions";

const RouteCreate = () => {
  const [selects, setSelects] = useState([]);
  const [selectedAudios, setSelectedAudios] = useState([]);
  const [selectedPois, setSelectedPois] = useState([]);
  const handleAddSelect = () => {
    if (selects.length < 20) {
      setSelects([...selects, { selectedOption: "" }]);
    } else {
      NotificationManager.error("You cannot add more then 20 points!");
    }
  };
  const handleDeleteSelect = (index) => {
    const updatedSelectsPois = [...selectedPois];
    updatedSelectsPois.splice(index, 1);
    setSelectedPois(updatedSelectsPois);

    const updatedSelectsAudios = [...selectedAudios];
    updatedSelectsAudios.splice(index, 1);
    setSelectedAudios(updatedSelectsAudios);

    const updatedSelects = [...selects];
    updatedSelects.splice(index, 1);
    setSelects(updatedSelects);
  };
  const handlePoiChange = (index, value) => {
    const updatedSelects = [...selectedPois];
    updatedSelects[index] = value;
    setSelectedPois(updatedSelects);
  };
  const handleAudioChange = (index, value) => {
    const updatedSelects = [...selectedAudios];
    updatedSelects[index] = value;
    setSelectedAudios(updatedSelects);
  };
  const dispatch = useDispatch();
  //meta title
  document.title = "Create Route";
  const { pois } = useSelector((state) => ({
    pois: state.pois.list,
  }));
  const { audios } = useSelector((state) => ({
    audios: state.audios.list,
  }));

  useEffect(() => {
    dispatch(getAudios());
    dispatch(getPois());
  }, []);

  const createPois = (select, index) => {
    let component = [];
    if (pois && pois.length > 0) {
      pois.forEach((poi) => {
        component.push(<option value={poi.id}>{poi.name}</option>);
      });
    }
    return (
      <select
        className="form-control"
        value={selectedPois[index]}
        onChange={(e) => {
          handlePoiChange(index, e.target.value);
        }}
      >
        {component}
      </select>
    );
  };
  const createAudios = (select, index) => {
    let component = [];
    if (audios && audios.length > 0) {
      audios.forEach((audio) => {
        component.push(<option value={audio.id}>{audio.name}</option>);
      });
    }
    return (
      <select
        className="form-control"
        value={selectedAudios[index]}
        onChange={(e) => handleAudioChange(index, e.target.value)}
      >
        {component}
      </select>
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    if (data.get("routeName") === "" || data.get("routeName") === undefined) {
      NotificationManager.error("Please Add the Route Name");
    } else {
      if (
        selectedAudios &&
        selectedAudios.length > 0 &&
        selectedPois &&
        selectedPois.length > 0
      ) {
        let routes = [];
        for (let i = 0; i < selectedAudios.length; i++) {
          routes.push({
            audio: selectedAudios[i],
            poi: selectedPois[i],
          });
        }
        const object = {
          name: data.get("routeName"),
          description: data.get("description"),
          date: new Date().toLocaleDateString(),
          routes: routes,
        };
        dispatch(addRoute(object));
        NotificationManager.success("Route Created!");
      } else {
        NotificationManager.error("Please Add at least one POI and Audio");
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Routes" breadcrumbItem="Create New" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Route</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="routeName"
                        className="col-form-label col-lg-2"
                      >
                        Route Name
                      </Label>
                      <Col lg="10">
                        <Input
                          id="routeName"
                          name="routeName"
                          type="text"
                          className="form-control"
                          placeholder="Enter Route Name..."
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="description"
                        className="col-form-label col-lg-2"
                      >
                        Route Description
                      </Label>
                      <Col lg="10">
                        <Input
                          id="description"
                          name="description"
                          type="textarea"
                        />
                      </Col>
                    </FormGroup>
                    <div className="inner form-group mb-0 row">
                      <Label className="col-form-label col-lg-2">Points</Label>
                      <div className="inner col-lg-10 ml-md-auto">
                        <div className="mb-3 row align-items-center">
                          {selects.map((select, index) => (
                            <Row key={index}>
                              <Col md="5">{createPois(select, index)}</Col>
                              <Col md="5">{createAudios(select, index)}</Col>
                              <Col md="2">
                                <Button
                                  className="m-2"
                                  color="danger"
                                  onClick={() => handleDeleteSelect(index)}
                                >
                                  Delete
                                </Button>
                                <Button
                                  onClick={handleAddSelect}
                                  className="m-2"
                                  color="success"
                                >
                                  Add more
                                </Button>
                              </Col>
                            </Row>
                          ))}
                          {selects.length === 0 && (
                            <Row>
                              <Col md="12">
                                <Button
                                  onClick={handleAddSelect}
                                  className="m-2"
                                  color="success"
                                >
                                  Add Points
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </div>
                      </div>
                    </div>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button type="submit" color="primary">
                          Create Route
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RouteCreate;
