import {
  GET_POIS,
  GET_POIS_SUCCESS,
  GET_POIS_ERROR,
  ADD_POI,
  ADD_POI_SUCCESS,
  ADD_POI_ERROR,
  EDIT_POI,
  EDIT_POI_ERROR,
  EDIT_POI_SUCCESS,
  DELETE_POI,
  DELETE_POI_SUCCESS,
  DELETE_POI_ERROR,
} from "./actionTypes";

export const getPois = () => {
  return {
    type: GET_POIS,
  };
};
export const getPoisSuccess = (pois) => {
  return {
    type: GET_POIS_SUCCESS,
    payload: pois,
  };
};
export const getPoisFail = (error) => {
  return {
    type: GET_POIS_ERROR,
    payload: error,
  };
};
export const addPoi = (poi) => {
  return {
    type: ADD_POI,
    payload: poi,
  };
};
export const addPoiSuccess = (poi) => {
  return {
    type: ADD_POI_SUCCESS,
    payload: poi,
  };
};
export const addPoiFail = (error) => {
  return {
    type: ADD_POI_ERROR,
    payload: error,
  };
};
export const editPoi = (poi) => {
  return {
    type: EDIT_POI,
    payload: poi,
  };
};
export const editPoiSuccess = (poi) => {
  return {
    type: EDIT_POI_SUCCESS,
    payload: poi,
  };
};
export const editPoiFail = (error) => {
  return {
    type: EDIT_POI_ERROR,
    payload: error,
  };
};

export const deletePoi = (poi) => {
  return {
    type: DELETE_POI,
    payload: poi,
  };
};
export const deletePoiSuccess = (poi) => {
  return {
    type: DELETE_POI_SUCCESS,
    payload: poi,
  };
};
export const deletePoiFail = (error) => {
  return {
    type: DELETE_POI_ERROR,
    payload: error,
  };
};
