import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getProfile } from "../../store/auth/profile/actions";
import { isLatitude, isLongitude } from "../../helpers/common_functions_helper";
import { NotificationManager } from "react-notifications";
import {addRequest, getRequests} from "../../store/requests/actions";

const RequestCreate = () => {
  document.title = "Create New Request ";
  const dispatch = useDispatch();
  const [checkLat, setCheckLat] = useState(false);
  const [checkLng, setCheckLng] = useState(false);

  const { currentUser } = useSelector((state) => ({
    currentUser: state.Profile.currentUser,
  }));
  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    setCheckLat(isLatitude(data.get("lat")));
    setCheckLng(isLongitude(data.get("lng")));

    if (isLatitude(data.get("lat")) && isLongitude(data.get("lng"))) {
      let object = {
        requestFrom: currentUser.attributes.email,
        location: {
          lat: data.get("lat"),
          lng: data.get("lng"),
        },
        createdAt: new Date().toUTCString(),
        status: "requested",
      };
      dispatch(addRequest(object));
      NotificationManager.success("Request Created!");
      dispatch(getRequests())
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Requests" breadcrumbItem="Create New" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Request</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <div className="inner-repeater mb-4">
                        <div className="inner form-group mb-0 row">
                          <Label className="col-form-label col-lg-2">
                            Coordinates
                          </Label>
                          <div
                            className="inner col-lg-10 ml-md-auto"
                            id="repeater"
                          >
                            <div
                              id={"nested"}
                              className="mb-3 row align-items-center"
                            >
                              <Col md="6">
                                <Input
                                  id="lat"
                                  name="lat"
                                  type="number"
                                  min="0.000000"
                                  step="0.0000001"
                                  max="90.000000"
                                  presicion={8}
                                  className="inner form-control"
                                  placeholder="Enter Latitude..."
                                  valid={checkLat}
                                />
                                {checkLat === true && (
                                  <FormFeedback valid>
                                    Valid Coordinate
                                  </FormFeedback>
                                )}
                              </Col>
                              <Col md="6">
                                <Input
                                  id="lng"
                                  name="lng"
                                  type="number"
                                  min="0.000000"
                                  step="0.0000001"
                                  max="90.000000"
                                  presicion={8}
                                  className="inner form-control"
                                  placeholder="Enter Longitude..."
                                  valid={checkLng}
                                />
                                {checkLng === true && (
                                  <FormFeedback valid>
                                    Valid Coordinate
                                  </FormFeedback>
                                )}
                              </Col>
                            </div>
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button type="submit" color="primary">
                          Create Request
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default RequestCreate;
