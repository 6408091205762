import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {deUmlaut} from "../../helpers/common_functions_helper";
import {
  getAudioUrl,
  getImageUrl,
  postAudio,
  postAudioInformation,
  postImage,
} from "../../helpers/backend_helper";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { getAudios } from "../../store/audio/actions";

const AudioCreate = () => {
  //meta title
  document.title = "Create New Audio ";
  const dispatch = useDispatch();
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        "audio/mpeg": [".mp3"],
      },
      maxFiles: 1,
    });

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      acceptedFiles.map((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        });
        const audio = document.createElement("audio");
        audio.src = file.preview;
        audio.addEventListener("loadedmetadata", () => {
          Object.assign(file, {
            duration: audio.duration,
          });
        });
      });
    }
  }, [acceptedFiles]);

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    );
  });

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    let object = {};
    const file = acceptedFiles[0];
    if (data.get("image").name !== "") {
      getImageUrl(deUmlaut(data.get("image").name), "audios").then((imageResponse) => {
        if (imageResponse.status === 200) {
          const url = imageResponse.data.url;
          postImage(url, data.get("image")).then((postImageResponse) => {
            if (postImageResponse.status === 200) {
              object.imageFile = data.get("image").name;
              object.image=  "https://locco-content-bucket231847-staging.s3.eu-central-1.amazonaws.com/public/images/audios/" + deUmlaut(data.get("image").name);
              object.name = data.get("audioName").charAt(0).toUpperCase() + data.get("audioName").slice(1);
              object.description = data.get("description");
              object.imageAttribution = data.get("imageAttribution");
              object.date = new Date().toISOString();
              object.duration = file.duration;
              startUploading(file, object);
            }
          });
        }
      });
    } else {
      object.name = data.get("audioName").charAt(0).toUpperCase() + data.get("audioName").slice(1);
      object.description = data.get("description");
      object.image = null;
      object.imageAttribution = data.get("imageAttribution");
      object.date = new Date().toISOString();
      object.duration = file.duration;
      startUploading(file, object);
    }
  };
  const startUploading = (file, object) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      NotificationManager.info("Uploading Started!");
      getAudioUrl(deUmlaut(file.name)).then((audioUrlResponse) => {
        if (audioUrlResponse.status === 200) {
          const url = audioUrlResponse.data.url;
          if (url) {
            postAudio(url, file).then((postAudioResponse) => {
              if (postAudioResponse.status === 200) {
                object.fileName = file.name;
                object.url = "https://locco-content-bucket231847-staging.s3.eu-central-1.amazonaws.com/public/audios/"+ deUmlaut(file.name);
                postAudioInformation(object).then(
                  (postAudioInformationResponse) => {
                    if (postAudioInformationResponse.status === 200) {
                      NotificationManager.success("Uploading Completed!");
                      dispatch(getAudios());
                    } else {
                      NotificationManager.error("Uploading Failed!");
                    }
                  }
                );
              } else {
                NotificationManager.error("Uploading Failed!");
              }
            });
          }
        } else {
          NotificationManager.error("Uploading Failed!");
        }
      });
    } else {
      NotificationManager.warning("No file selected", "Please select a file");
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Audios" breadcrumbItem="Create New" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Audio</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="audioName"
                        className="col-form-label col-lg-2"
                      >
                        Audio Name
                      </Label>
                      <Col lg="10">
                        <Input
                          id="audioName"
                          name="audioName"
                          type="text"
                          className="form-control"
                          placeholder="Enter Audio Name..."
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="description"
                        className="col-form-label col-lg-2"
                      >
                        Audio Description
                      </Label>
                      <Col lg="10">
                        <Input
                          id="description"
                          name="description"
                          type="textarea"
                          placeholder="Enter Audio Description..."
                        />
                      </Col>
                    </FormGroup>
                    {/*<FormGroup className="mb-4" row>*/}
                    {/*    <Label className="col-form-label col-lg-2">*/}
                    {/*        Audio Date*/}
                    {/*    </Label>*/}
                    {/*    <Col lg="10">*/}
                    {/*        <Row>*/}
                    {/*            <Col md={12} className="pr-0">*/}
                    {/*                <DatePicker*/}
                    {/*                    className="form-control"*/}
                    {/*                    selected={date}*/}
                    {/*                    onChange={handleDateChange}*/}
                    {/*                />*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*    </Col>*/}
                    {/*</FormGroup>*/}
                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">
                        Audio Image
                      </Label>
                      <Col lg="10">
                        <Row>
                          <Col md={12} className="pr-0">
                            <div className="mt-12 mt-md-0">
                              <Input
                                id="image"
                                name="image"
                                type="file"
                                accept="image/*"
                                className="form-control"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="imageAttribution"
                        className="col-form-label col-lg-2"
                      >
                        Image Attribution
                      </Label>
                      <Col lg="10">
                        <Input
                          id="imageAttribution"
                          name="imageAttribution"
                          type="text"
                          className="form-control"
                          placeholder="Enter Image Attribution..."
                        />
                      </Col>
                    </FormGroup>
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        Audio File
                      </Label>
                      <Col lg="10">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                            <h5>Only one Audio can be uploaded</h5>
                          </div>
                        </div>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {acceptedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col>
                                      <p className="mb-0">
                                        <strong>{f.name}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                          {fileRejectionItems &&
                            fileRejectionItems.length > 0 && (
                              <div>
                                <h4>Rejected files</h4>
                                <ul>{fileRejectionItems}</ul>
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button type="submit" color="primary">
                          Create Audio
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AudioCreate;
