import {
  ADD_ROUTE,
  ADD_ROUTE_ERROR,
  ADD_ROUTE_SUCCESS,
  DELETE_ROUTE,
  DELETE_ROUTE_ERROR,
  DELETE_ROUTE_SUCCESS,
  EDIT_ROUTE,
  EDIT_ROUTE_ERROR,
  EDIT_ROUTE_SUCCESS,
  GET_ROUTES,
  GET_ROUTES_ERROR,
  GET_ROUTES_SUCCESS,
} from "./actionTypes";

export const getRoutes = () => {
  return {
    type: GET_ROUTES,
  };
};
export const getRoutesSuccess = (routes) => {
  return {
    type: GET_ROUTES_SUCCESS,
    payload: routes,
  };
};
export const getRoutesFail = (error) => {
  return {
    type: GET_ROUTES_ERROR,
    payload: error,
  };
};
export const addRoute = (route) => {
  return {
    type: ADD_ROUTE,
    payload: route,
  };
};
export const addRouteSuccess = (route) => {
  return {
    type: ADD_ROUTE_SUCCESS,
    payload: route,
  };
};
export const addRouteFail = (error) => {
  return {
    type: ADD_ROUTE_ERROR,
    payload: error,
  };
};
export const editRoute = (route) => {
  return {
    type: EDIT_ROUTE,
    payload: route,
  };
};
export const editRouteSuccess = (route) => {
  return {
    type: EDIT_ROUTE_SUCCESS,
    payload: route,
  };
};
export const editRouteFail = (error) => {
  return {
    type: EDIT_ROUTE_ERROR,
    payload: error,
  };
};

export const deleteRoute = (route) => {
  return {
    type: DELETE_ROUTE,
    payload: route,
  };
};
export const deleteRouteSuccess = (route) => {
  return {
    type: DELETE_ROUTE_SUCCESS,
    payload: route,
  };
};
export const deleteRouteFail = (error) => {
  return {
    type: DELETE_ROUTE_ERROR,
    payload: error,
  };
};
