import { call, put, takeEvery } from "redux-saga/effects";
import {
  addRequestFail,
  addRequestSuccess,
  deleteRequestFail,
  deleteRequestSuccess,
  editRequestFail,
  editRequestSuccess,
  getRequestsFail,
  getRequestsSuccess,
} from "./actions";
import {
  ADD_REQUEST,
  DELETE_REQUEST,
  EDIT_REQUEST,
  GET_REQUESTS,
} from "./actionTypes";
import {
  getRequestList,
  deleteRequest,
  postRequest,
  updateRequest,
} from "../../helpers/backend_helper";

// Login Redux States

function* fetchRequests() {
  try {
    const response = yield call(getRequestList);
    yield put(getRequestsSuccess(response));
  } catch (error) {
    yield put(getRequestsFail(error));
  }
}
function* addRequest({ payload: audio }) {
  try {
    const response = yield call(postRequest(audio));
    yield put(addRequestSuccess(response));
  } catch (err) {
    yield put(addRequestFail(err));
  }
}
function* onUpdateRequest({ payload: audio }) {
  try {
    const response = yield call(updateRequest, audio);
    yield put(editRequestSuccess(response));
  } catch (error) {
    yield put(editRequestFail(error));
  }
}

function* onDeleteRequest({ payload: audio }) {
  try {
    const response = yield call(deleteRequest, audio);
    yield put(deleteRequestSuccess(response));
  } catch (error) {
    yield put(deleteRequestFail(error));
  }
}

function* requestsSaga() {
  yield takeEvery(GET_REQUESTS, fetchRequests);
  yield takeEvery(ADD_REQUEST, addRequest);
  yield takeEvery(EDIT_REQUEST, onUpdateRequest);
  yield takeEvery(DELETE_REQUEST, onDeleteRequest);
}

export default requestsSaga;
