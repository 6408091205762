import {
  GET_AUDIOS_SUCCESS,
  GET_AUDIOS_ERROR,
  ADD_AUDIO_SUCCESS,
  ADD_AUDIO_ERROR,
  DELETE_AUDIO_ERROR,
  DELETE_AUDIO_SUCCESS,
  EDIT_AUDIO_ERROR,
  EDIT_AUDIO_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  list: [],
  addedAudio: {},
  error: {},
};

const audios = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AUDIOS_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };

    case GET_AUDIOS_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_AUDIO_SUCCESS:
      return {
        ...state,
        addedAudio: action.payload,
      };

    case ADD_AUDIO_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case EDIT_AUDIO_SUCCESS:
      return {
        ...state,
        list: state.list.map((audio) =>
          audio.id.toString() === action.payload.id.toString()
            ? { audio, ...action.payload }
            : audio
        ),
      };

    case EDIT_AUDIO_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_AUDIO_SUCCESS:
      return {
        ...state,
        list: state.list.filter(
          (audio) => audio.id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_AUDIO_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default audios;
