import {
  ADD_ROUTE_ERROR,
  ADD_ROUTE_SUCCESS,
  DELETE_ROUTE_ERROR,
  DELETE_ROUTE_SUCCESS,
  EDIT_ROUTE_ERROR,
  EDIT_ROUTE_SUCCESS,
  GET_ROUTES_ERROR,
  GET_ROUTES_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  list: [],
  addedRoute: {},
  error: {},
};

const routes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROUTES_SUCCESS:
      return {
        ...state,
        list: action.payload.data,
      };

    case GET_ROUTES_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_ROUTE_SUCCESS:
      return {
        ...state,
        addedRoute: action.payload,
      };

    case ADD_ROUTE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case EDIT_ROUTE_SUCCESS:
      return {
        ...state,
        list: state.list.map((route) =>
          route.id.toString() === action.payload.id.toString()
            ? { route, ...action.payload }
            : route
        ),
      };

    case EDIT_ROUTE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_ROUTE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(
          (route) => route.id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_ROUTE_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default routes;
