import {
    GET_MOST_POIS_ERROR,
    GET_MOST_POIS_SUCCESS,
    GET_MOST_USERS_ERROR,
    GET_MOST_USERS_SUCCESS
} from "./actionTypes";

const INIT_STATE={
    mostPois:[],
    mostUsers:[],
    error:{}
}
const events = (state=INIT_STATE, action) =>{
    switch (action.type){
        case GET_MOST_POIS_SUCCESS:
            return{
                ...state,
                mostPois: action.payload
            }
        case GET_MOST_POIS_ERROR:
            return{
                ...state,
                error: action.payload
            }
        case GET_MOST_USERS_SUCCESS:
            return{
                ...state,
                mostUsers: action.payload
            }
        case GET_MOST_USERS_ERROR:
            return{
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}
export default events
