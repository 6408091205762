import React, { useEffect, useState } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Label,
  Input,
  FormGroup, Pagination,PaginationItem, PaginationLink,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleMap from "../Maps/LeafletMap/SimpleMap";
import { useDispatch, useSelector } from "react-redux";
import { deletePoi, getPois } from "../../store/pois/actions";
import DeleteModal from "../../components/Common/DeleteModal";
import { NotificationManager } from "react-notifications";
import { getAudios } from "../../store/audio/actions";
import PoiEdit from "./modals/poi-edit";

const PoiList = () => {
  const [pageSize, setPageSize] = useState(10)
  const [modalOpen, setModalOpen] = useState(false);
  const [editedPoi, setEditedPoi] = useState(null);
  const [deletedPoi, setDeletedPoi] = useState(null);
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentState, setCurrentState] = useState(0);
  const [pageState, setPageState] = useState(0);
  const [searchedName, setSearchedName] = useState()
  const { pois } = useSelector((state) => ({
    pois: state.pois.list,
  }));
  const [searchedPois, setSearchedPois] = useState(pois)
  useEffect(() => {
    setPageState(Math.ceil(searchedPois.length / pageSize));
  }, [pageSize]);

  const handlePagination = (e, currentPage) => {
    e.preventDefault();
    setCurrentState(currentPage);
  };
  const handlePageSize = (value) =>{
    setPageState(Math.ceil(searchedPois.length / value));
    setPageSize(value)
  }
  useEffect(() => {
    if(searchedName){
      let searchedPois = pois.filter((poi) => poi.name.toLowerCase().includes(searchedName))
      setSearchedPois(searchedPois)
    }
    else{
      setSearchedPois(pois)
    }
  }, [searchedName]);

  const onClickDelete = (poi) => {
    setDeleteModal(true);
    setDeletedPoi(poi);
  };

  const handleDeletePoi = () => {
    NotificationManager.info("Deletion Started!");
    dispatch(deletePoi(deletedPoi));
    setDeleteModal(false);
    dispatch(getPois());
    NotificationManager.success("Deletion Completed!");
  };

  useEffect(() => {
    dispatch(getPois());
    dispatch(getAudios());
  }, []);

  //meta title
  document.title = "Poi List";
  const handleSearchedItems = (value) =>{
    setSearchedName(value)
  }
  const createPoiLayout = () => {
    if (searchedPois && searchedPois.length > 0) {
      let layout = [];
      searchedPois.sort((a, b) => a.name > b.name ? 1 : -1).slice(currentState * pageSize, (currentState +1 )* pageSize).forEach((poi, index) => {
        layout.push(
          <Col key={index} xl="4" sm="6">
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">{poi.name}</h4>
                <div id="leaflet-map" className="leaflet-map">
                  {poi && poi.location && (
                    <SimpleMap lat={poi.location.lat} lng={poi.location.lng} />
                  )}
                </div>
                <p className="mt-4 mb-4">{poi.description}</p>
                <Button
                  style={{ marginRight: 5 }}
                  color="success"
                  className="inner"
                  onClick={() => {
                    setEditedPoi(poi);
                    setModalOpen(true)
                  }}
                >
                  Edit
                </Button>
                <Button
                  color="danger"
                  className="inner"
                  onClick={() => {
                    onClickDelete(poi);
                  }}
                >
                  Delete
                </Button>
              </CardBody>
            </Card>
          </Col>
        );
      });
      return layout;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Poi" breadcrumbItem="List" />
          <Form>
            <FormGroup defaultValue={20} onChange={event => handlePageSize(event.target.value)}>
              <Label for="exampleSelect">
                Page Size
              </Label>
              <Input
                  id="exampleSelect"
                  name="select"
                  type="select"
              >
                <option>
                  10
                </option>
                <option>
                  20
                </option>
                <option>
                  30
                </option>
                <option>
                  40
                </option>
                <option>
                  50
                </option>
              </Input>
            </FormGroup>
          </Form>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeletePoi}
            onCloseClick={() => setDeleteModal(false)}
            title={"Are you sure you want to permanently delete the POI?"}
          />
          <Form>
            <FormGroup onChange={event => handleSearchedItems(event.target.value)}>
              <Label for="exampleSearch">
                Search
              </Label>
              <Input
                  id="exampleSearch"
                  name="search"
                  placeholder="Name"
                  type="search"
              />
            </FormGroup>
          </Form>
          <Row>{createPoiLayout()}</Row>
          {editedPoi && <PoiEdit editedPoi={editedPoi} modal={modalOpen} setModal={setModalOpen} setEditedPoi={setEditedPoi}/>}
          <Pagination size="md" aria-label="Page navigation Poi List">
            <PaginationItem disabled={currentState <= 0}>
              <PaginationLink previous />
            </PaginationItem>
            {[...Array(pageState)].map((page, i) => (
                <PaginationItem active={i === currentState} key={i}>
                  <PaginationLink onClick={e => handlePagination(e, i)} href="#">
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
            ))}
            <PaginationItem  disabled={currentState >= pageState - 1}>
              <PaginationLink next />
            </PaginationItem>
          </Pagination>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PoiList;
