/* CONTACTS */
export const GET_CONTACTS = "GET_CONTACTS";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";
export const GET_CONTACTS_FAIL = "GET_CONTACTS_FAIL";

/* CONTACTS PROFILE */
export const GET_CONTACT_PROFILE = "GET_CONTACT_PROFILE";
export const GET_CONTACT_PROFILE_SUCCESS = "GET_CONTACT_PROFILE_SUCCESS";
export const GET_CONTACT_PROFILE_FAIL = "GET_CONTACT_PROFILE_FAIL";

/**
 * add user
 */
export const ADD_NEW_CONTACT = "ADD_NEW_CONTACT";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAIL = "ADD_CONTACT_FAIL";

/**
 * Edit user
 */
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_FAIL = "UPDATE_CONTACT_FAIL";

/**
 * Delete user
 */
export const DELETE_CONTACT = "DELETE_CONTACT";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAIL = "DELETE_CONTACT_FAIL";
