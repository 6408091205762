import {
  GET_REQUESTS,
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_ERROR,
  ADD_REQUEST,
  ADD_REQUEST_SUCCESS,
  ADD_REQUEST_ERROR,
  DELETE_REQUEST_ERROR,
  DELETE_REQUEST_SUCCESS,
  DELETE_REQUEST,
  EDIT_REQUEST_ERROR,
  EDIT_REQUEST_SUCCESS,
  EDIT_REQUEST, SET_REQUEST_FILTER,
} from "./actionTypes";

export const getRequests = () => {
  return {
    type: GET_REQUESTS,
  };
};
export const getRequestsSuccess = (requests) => {
  return {
    type: GET_REQUESTS_SUCCESS,
    payload: requests,
  };
};
export const getRequestsFail = (error) => {
  return {
    type: GET_REQUESTS_ERROR,
    payload: error,
  };
};
export const addRequest = (request) => {
  return {
    type: ADD_REQUEST,
    payload: request,
  };
};
export const addRequestSuccess = (request) => {
  return {
    type: ADD_REQUEST_SUCCESS,
    payload: request,
  };
};
export const addRequestFail = (error) => {
  return {
    type: ADD_REQUEST_ERROR,
    payload: error,
  };
};
export const editRequest = (request) => {
  return {
    type: EDIT_REQUEST,
    payload: request,
  };
};
export const editRequestSuccess = (request) => {
  return {
    type: EDIT_REQUEST_SUCCESS,
    payload: request,
  };
};
export const editRequestFail = (error) => {
  return {
    type: EDIT_REQUEST_ERROR,
    payload: error,
  };
};

export const setRequestFilter = (filter) =>{
  return {
    type: SET_REQUEST_FILTER,
    payload: filter
  }
}

export const deleteRequest = (request) => {
  return {
    type: DELETE_REQUEST,
    payload: request,
  };
};
export const deleteRequestSuccess = (request) => {
  return {
    type: DELETE_REQUEST_SUCCESS,
    payload: request,
  };
};
export const deleteRequestFail = (error) => {
  return {
    type: DELETE_REQUEST_ERROR,
    payload: error,
  };
};
