import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Form,
  Button,
  FormFeedback,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { addPoi } from "../../store/pois/actions";
import {
  deUmlaut,
  isLatitude,
  isLongitude,
} from "../../helpers/common_functions_helper";
import { NotificationManager } from "react-notifications";
import { getAudios } from "../../store/audio/actions";
import { poiTypes } from "../../types/PoiTypes";
import {getImageUrl, postImage} from "../../helpers/backend_helper";

const PoiCreate = () => {
  const [selects, setSelects] = useState([]);
  const [selectedAudios, setSelectedAudios] = useState([]);
  //meta title
  document.title = "Create POI";
  const [checkPoiName, setPoiName] = useState(false);
  const [checkLat, setCheckLat] = useState(false);
  const [checkLng, setCheckLng] = useState(false);
  const dispatch = useDispatch();

  const createOptions = () => {
    let component = [];
    poiTypes
      .sort((a, b) => a.value.localeCompare(b.value))
      .forEach((type, index) => {
        component.push(<option key={index}>{type.value}</option>);
      });
    return component;
  };

  const { audios } = useSelector((state) => ({
    audios: state.audios.list,
  }));

  const handleAudioChange = (index, value) => {
    const updatedSelects = [...selectedAudios];
    updatedSelects[index] = value;
    setSelectedAudios(updatedSelects);
  };
  const checkLatValid = (index) => {
    return isLatitude(selects[index].lat);
  };
  const checkLngValid = (index) => {
    return isLongitude(selects[index].lng);
  };
  const handleTriggerPointLat = (index, value) => {
    const updatedSelects = [...selects];
    updatedSelects[index].lat = value;
    setSelects(updatedSelects);
  };
  const handleTriggerPointLng = (index, value) => {
    const updatedSelects = [...selects];
    updatedSelects[index].lng = value;
    setSelects(updatedSelects);
  };

  const createTriggerPoints = (select, index) => {
    return (
      <div className="inner col-lg-10 ml-md-auto" id="repeater" key={index}>
        <div id={"nested"} className="mb-3 row align-items-center">
          <Col md="6" key={index}>
            <Input
              id="lat"
              name="lat"
              type="number"
              min="0.000000"
              step="0.0000001"
              max="90.000000"
              presicion={8}
              valid={checkLatValid(index)}
              onChange={(event) =>
                handleTriggerPointLat(index, event.target.value)
              }
              className="inner form-control"
              placeholder="Enter Latitude..."
            />
            <FormFeedback valid>Valid Coordinate</FormFeedback>
          </Col>
          <Col md="6">
            <Input
              id="lng"
              name="lng"
              type="number"
              min="0.000000"
              step="0.0000001"
              max="90.000000"
              valid={checkLngValid(index)}
              presicion={8}
              onChange={(event) =>
                handleTriggerPointLng(index, event.target.value)
              }
              className="inner form-control"
              placeholder="Enter Longitude..."
            />
            <FormFeedback valid>Valid Coordinate</FormFeedback>
          </Col>
        </div>
      </div>
    );
  };

  const createAudios = (select, index) => {
    let component = [];
    if (audios && audios.length > 0) {
      audios.sort((a, b) => a.name > b.name ? 1 : -1).forEach((audio) => {
        component.push(<option value={audio.id}>{audio.name}</option>);
      });
    }
    return (
      <select
        className="form-control"
        value={selectedAudios[index]}
        onChange={(e) => handleAudioChange(index, e.target.value)}
      >
        {component}
      </select>
    );
  };
  const handleAddSelect = () => {
    setSelects([...selects, { lat: 0.0, lng: 0.0 }]);
    setSelectedAudios([audios[0].id]);
  };
  const handleDeleteSelect = (index) => {
    const updatedSelectsAudios = [...selectedAudios];
    updatedSelectsAudios.splice(index, 1);
    setSelectedAudios(updatedSelectsAudios);

    const updatedSelects = [...selects];
    updatedSelects.splice(index, 1);
    setSelects(updatedSelects);
  };

  useEffect(() => {
    dispatch(getAudios());
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    if (data.get("poiName") === "" || data.get("poiName") === undefined) {
      setPoiName(false);
    } else setPoiName(true);
    setCheckLat(isLatitude(data.get("lat")));
    setCheckLng(isLongitude(data.get("lng")));

    if (
      checkPoiName &&
      isLatitude(data.get("lat")) &&
      isLongitude(data.get("lng"))
    ) {
      const triggerPoints = [];
      for (let i = 0; i < selects.length; i++) {
        triggerPoints.push({
          coordinate: selects[i],
          audio: selectedAudios[i],
        });
      }
      if (data.get("image").name !== "") {
        getImageUrl(deUmlaut(data.get("image").name), "pois").then((imageResponse) =>{
          if(imageResponse.status === 200){
            const url = imageResponse.data.url;
            postImage(url,data.get("image")).then((postImageResponse) =>{
              if(postImageResponse.status === 200){
                const object = {
                  name: data.get("poiName").charAt(0).toUpperCase() + data.get("poiName").slice(1),
                  description: data.get("description"),
                  image: new URL("https://locco-content-bucket231847-staging.s3.eu-central-1.amazonaws.com/public/images/pois/"+ deUmlaut(data.get("image").name).toString()),
                  createdAt: new Date().toLocaleDateString(),
                  location: {
                    lat: parseFloat(data.get("lat")),
                    lng: parseFloat(data.get("lng")),
                  },
                  type: poiTypes.filter((type) => type.value === data.get("type"))[0]["key"],
                  notificationTitle: data.get("notificationTitle"),
                  triggerPoints: triggerPoints,
                };
                NotificationManager.success("POI Created!");
                dispatch(addPoi(object));
                event.target.reset();
              }
            })
          }
        })
      }
      else{
        const object = {
          name: data.get("poiName").charAt(0).toUpperCase() + data.get("poiName").slice(1),
          description: data.get("description"),
          image: null,
          createdAt: new Date().toLocaleDateString(),
          location: {
            lat: parseFloat(data.get("lat")),
            lng: parseFloat(data.get("lng")),
          },
          type: poiTypes.filter((type) => type.value === data.get("type"))[0]["key"],
          notificationTitle: data.get("notificationTitle"),
          triggerPoints: triggerPoints,
        };
        dispatch(addPoi(object));
        event.target.reset();
        NotificationManager.success("POI Created!");
      }
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Poi" breadcrumbItem="Create" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Poi</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="poiName"
                        className="col-form-label col-lg-2"
                      >
                        Poi Name
                      </Label>
                      <Col lg="10">
                        <Input
                          id="poiName"
                          name="poiName"
                          type="text"
                          className="form-control"
                          placeholder="Enter Poi Name..."
                          valid={checkPoiName}
                        />
                        {checkPoiName === true && (
                          <FormFeedback valid>Valid Name</FormFeedback>
                        )}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">
                        Poi Description
                      </Label>
                      <Col lg="10">
                        <Input
                          id="description"
                          name="description"
                          type="textarea"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">
                        Poi Image
                      </Label>
                      <Col lg="10">
                        <Row>
                          <Col md={12} className="pr-0">
                            <div className="mt-12 mt-md-0">
                              <Input
                                id="image"
                                name="image"
                                type="file"
                                accept="image/*"
                                className="form-control"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <div className="inner-repeater mb-4">
                        <div className="inner form-group mb-0 row">
                          <Label className="col-form-label col-lg-2">
                            POI Coordinate
                          </Label>
                          <div
                            className="inner col-lg-10 ml-md-auto"
                            id="repeater"
                          >
                            <div
                              id={"nested"}
                              className="mb-3 row align-items-center"
                            >
                              <Col md="6">
                                <Input
                                  id="lat"
                                  name="lat"
                                  type="number"
                                  min="0.000000"
                                  step="0.0000001"
                                  max="90.000000"
                                  presicion={8}
                                  className="inner form-control"
                                  placeholder="Enter Latitude..."
                                  valid={checkLat}
                                />
                                {checkLat === true && (
                                  <FormFeedback valid>
                                    Valid Coordinate
                                  </FormFeedback>
                                )}
                              </Col>
                              <Col md="6">
                                <Input
                                  id="lng"
                                  name="lng"
                                  type="number"
                                  min="0.000000"
                                  step="0.0000001"
                                  max="90.000000"
                                  presicion={8}
                                  className="inner form-control"
                                  placeholder="Enter Longitude..."
                                  valid={checkLng}
                                />
                                {checkLng === true && (
                                  <FormFeedback valid>
                                    Valid Coordinate
                                  </FormFeedback>
                                )}
                              </Col>
                            </div>
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                    <div className="inner form-group mb-0 row">
                      <Label className="col-form-label col-lg-2">
                        Trigger Points
                      </Label>
                      <div className="inner col-lg-10 ml-md-auto">
                        <div className="mb-3 row align-items-center">
                          {selects.map((select, index) => (
                            <Row key={index}>
                              <Col md="5">
                                {createTriggerPoints(select, index)}
                              </Col>
                              <Col md="3">{createAudios(select, index)}</Col>
                              <Col md="3">
                                <Button
                                  style={{ marginRight: 5 }}
                                  color="danger"
                                  onClick={() => handleDeleteSelect(index)}
                                >
                                  Delete
                                </Button>
                                <Button
                                  onClick={handleAddSelect}
                                  color="success"
                                >
                                  Add more
                                </Button>
                              </Col>
                            </Row>
                          ))}

                          {selects.length === 0 && (
                            <Row>
                              <Col md="12">
                                <Button
                                  onClick={handleAddSelect}
                                  className="m-2"
                                  color="success"
                                >
                                  Add Trigger Points
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </div>
                      </div>
                    </div>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="notificationTitle"
                        className="col-form-label col-lg-2"
                      >
                        Notification Title
                      </Label>
                      <Col lg="10">
                        <Input
                          id="notificationTitle"
                          name="notificationTitle"
                          type="text"
                          className="form-control"
                          placeholder="Notification Title"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Row className="mb-3">
                        <label
                          htmlFor="taskbudget"
                          className="col-form-label col-lg-2"
                        >
                          Type
                        </label>
                        <div className="col-md-10">
                          <Input id="type" name="type" type="select">
                            {createOptions()}
                          </Input>
                        </div>
                      </Row>
                    </FormGroup>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button type="submit" color="primary">
                          Create Poi
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PoiCreate;
