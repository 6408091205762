export const GET_JOB_LIST = "GET_JOBLIST";
export const GET_JOB_LIST_FAIL = "GET_JOB_LIST_FAIL";
export const GET_JOB_LIST_SUCCESS = "GET_JOB_LIST_SUCCESS";
export const ADD_NEW_JOB_LIST = "ADD_NEW_JOB_LIST";
export const ADD_JOB_LIST_SUCCESS = "ADD_JOB_LIST_SUCCESS";
export const ADD_JOB_LIST_FAIL = "ADD_JOB_LIST_FAIL";
export const UPDATE_JOB_LIST = "UPDATE_JOB_LIST";
export const UPDATE_JOB_LIST_SUCCESS = "UPDATE_JOB_LIST_SUCCESS";
export const UPDATE_JOB_LIST_FAIL = "UPDATE_JOB_LIST_FAIL";
export const DELETE_JOB_LIST = "DELETE_JOB_LIST";
export const DELETE_JOB_LIST_SUCCESS = "DELETE_JOB_LIST_SUCCESS";
export const DELETE_JOB_LIST_FAIL = "DELETE_JOB_LIST_FAIL";
export const GET_APPLY_JOB = "GET_APPLY_JOB";
export const GET_APPLY_JOB_SUCCESS = "GET_APPLY_JOB_SUCCESS";
export const GET_APPLY_JOB_FAIL = "GET_APPLY_JOB_FAIL";
export const DELETE_APPLY_JOB = "DELETE_APPLY_JOB";
export const DELETE_APPLY_JOB_SUCCESS = "DELETE_APPLY_JOB_SUCCESS";
export const DELETE_APPLY_JOB_FAIL = "DELETE_APPLY_JOB_FAIL";
