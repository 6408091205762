import {
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_ERROR,
  ADD_REQUEST_SUCCESS,
  ADD_REQUEST_ERROR,
  DELETE_REQUEST_ERROR,
  DELETE_REQUEST_SUCCESS,
  EDIT_REQUEST_ERROR,
  EDIT_REQUEST_SUCCESS, SET_REQUEST_FILTER,
} from "./actionTypes";

const INIT_STATE = {
  statusFilter: "all",
  list: [],
  addedRequest: {},
  error: {},
};

const requests = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REQUESTS_SUCCESS:{
      if(state.statusFilter !== "all"){
        const list = action.payload
        let filteredList = list.filter((item) => item.status === state.statusFilter).sort((a,b) => new Date(a.createdAt) > new Date(b.createdAt) ? 1: -1)
        return {
          ...state,
          list: filteredList
        }
      }
      else{
        return {
          ...state,
          list: action.payload.sort((a,b) => new Date(a.createdAt) > new Date(b.createdAt) ? 1: -1),
        };
      }
    }
    case SET_REQUEST_FILTER:{
      return {
        ...state,
        statusFilter: action.payload
      }
    }

    case GET_REQUESTS_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_REQUEST_SUCCESS:
      return {
        ...state,
        addedRequest: action.payload,
      };

    case ADD_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case EDIT_REQUEST_SUCCESS:
      return {
        ...state,
        list: state.list.map((request) =>
          request.id.toString() === action.payload.id.toString()
            ? { request, ...action.payload }
            : request
        ),
      };

    case EDIT_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        list: state.list.filter(
          (request) => request.id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default requests;
