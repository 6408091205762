import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  addAudioFail,
  addAudioSuccess,
  deleteAudioFail,
  deleteAudioSuccess,
  editAudioFail,
  editAudioSuccess,
  getAudiosFail,
  getAudiosSuccess,
} from "./actions";
import { ADD_AUDIO, DELETE_AUDIO, EDIT_AUDIO, GET_AUDIOS } from "./actionTypes";
import {
  getAudioList,
  deleteAudio,
  postAudio,
  updateAudio,
} from "../../helpers/backend_helper";

// Login Redux States

function* fetchAudios() {
  try {
    const response = yield call(getAudioList);
    yield put(getAudiosSuccess(response));
  } catch (error) {
    yield put(getAudiosFail(error));
  }
}
function* addAudio({ payload: audio }) {
  try {
    const response = yield call(postAudio(audio));
    yield put(addAudioSuccess(response));
  } catch (err) {
    yield put(addAudioFail(err));
  }
}
function* onUpdateAudio({ payload: audio }) {
  try {
    const response = yield call(updateAudio, audio);
    yield put(editAudioSuccess(response));
  } catch (error) {
    yield put(editAudioFail(error));
  }
}

function* onDeleteAudio({ payload: audio }) {
  try {
    const response = yield call(deleteAudio, audio);
    yield put(deleteAudioSuccess(response));
  } catch (error) {
    yield put(deleteAudioFail(error));
  }
}

function* audiosSaga() {
  yield takeEvery(GET_AUDIOS, fetchAudios);
  yield takeEvery(ADD_AUDIO, addAudio);
  yield takeEvery(EDIT_AUDIO, onUpdateAudio);
  yield takeEvery(DELETE_AUDIO, onDeleteAudio);
}

export default audiosSaga;
