import { call, put, takeEvery } from "redux-saga/effects";
import {
  addRouteFail,
  addRouteSuccess,
  deleteRouteFail,
  deleteRouteSuccess,
  editRouteFail,
  editRouteSuccess,
  getRoutesFail,
  getRoutesSuccess,
} from "./actions";
import { ADD_ROUTE, DELETE_ROUTE, EDIT_ROUTE, GET_ROUTES } from "./actionTypes";
import {
  deleteRoute,
  getRouteList,
  postRoute,
  updateRoute,
} from "../../helpers/backend_helper";

// Login Redux States

function* fetchRoutes() {
  try {
    const response = yield call(getRouteList);
    yield put(getRoutesSuccess(response));
  } catch (error) {
    yield put(getRoutesFail(error));
  }
}
function* addRoute({ payload: route }) {
  try {
    const response = yield call(postRoute(route));
    yield put(addRouteSuccess(response));
  } catch (err) {
    yield put(addRouteFail(err));
  }
}
function* onUpdateRoute({ payload: route }) {
  try {
    const response = yield call(updateRoute, route);
    yield put(editRouteSuccess(response));
  } catch (error) {
    yield put(editRouteFail(error));
  }
}

function* onDeleteRoute({ payload: route }) {
  try {
    const response = yield call(deleteRoute, route);
    yield put(deleteRouteSuccess(response));
  } catch (error) {
    yield put(deleteRouteFail(error));
  }
}

function* routeSaga() {
  yield takeEvery(GET_ROUTES, fetchRoutes);
  yield takeEvery(ADD_ROUTE, addRoute);
  yield takeEvery(EDIT_ROUTE, onUpdateRoute);
  yield takeEvery(DELETE_ROUTE, onDeleteRoute);
}

export default routeSaga;
