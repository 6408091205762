import React, { Component, useState } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
const blueIcon = L.icon({
  iconUrl: require("../../../assets/images/icons/marker-blue.png"),
  iconSize:     [32, 32], // size of the icon
});

const SimpleMap = (props) => {
  const { lat, lng } = props;
  const [zoom, setZoom] = useState(13);
  const { height, width } = props;

  return (
    <Map
      center={[lat ? lat : 51.505, lng ? lng : -0.09]}
      zoom={zoom}
      style={{ height: height ? height + "px" : "300px" }}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker icon={blueIcon} position={[lat ? lat : 51.505, lng ? lng : -0.09]}></Marker>
    </Map>
  );
};
export default SimpleMap;
