import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_USERS,
  ADD_NEW_USER,
  DELETE_USER,
  UPDATE_USER, GET_SOCIAL_USERS, DELETE_SOCIAL_USER,
} from "./actionTypes";

import {
  getUsersSuccess,
  getUsersFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getSocialUsersSuccess,
  getSocialUsersFail,
  deleteSocialUserSuccess,
  deleteSocialUserFail,
} from "./actions";
import {
  createCognitoUser,
  deleteCognitoUser, deleteSocialUserFromDatabase,
  getCognitoUsers, getSocialUsersFromBackend,
  updateCognitoUser,
} from "../../helpers/backend_helper";

//Include Both Helper File with needed methods

function* fetchUsers() {
  try {
    const response = yield call(getCognitoUsers);
    yield put(getUsersSuccess(response));
  } catch (error) {
    yield put(getUsersFail(error));
  }
}

function* fetchSocialUsers(){
  try{
    const response = yield call(getSocialUsersFromBackend)
    yield put(getSocialUsersSuccess(response))
  }catch(error){
    yield put(getSocialUsersFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateCognitoUser, user);
    yield put(updateUserSuccess(response));
  } catch (error) {
    yield put(updateUserFail(error));
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteCognitoUser, user);
    yield put(deleteUserSuccess(response));
  } catch (error) {
    yield put(deleteUserFail(error));
  }
}

function* onDeleteSocialUser({ payload: user }) {
  try {
    const response = yield call(deleteSocialUserFromDatabase, user);
    yield put(deleteSocialUserSuccess(response));
  } catch (error) {
    yield put(deleteSocialUserFail(error));
  }
}

function* onAddNewUser({ payload: user }) {
  try {
    const response = yield call(createCognitoUser, user);

    yield put(addUserSuccess(response));
  } catch (error) {
    yield put(addUserFail(error));
  }
}

function* userSaga() {
  yield takeEvery(GET_USERS, fetchUsers);
  yield takeEvery(GET_SOCIAL_USERS,fetchSocialUsers);
  yield takeEvery(ADD_NEW_USER, onAddNewUser);
  yield takeEvery(UPDATE_USER, onUpdateUser);
  yield takeEvery(DELETE_USER, onDeleteUser);
  yield takeEvery(DELETE_SOCIAL_USER, onDeleteSocialUser);
}

export default userSaga;
