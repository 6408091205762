import React, {useEffect, useState, useRef} from "react";
import withRouter from "components/Common/withRouter";

import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

//redux
import {useSelector, useDispatch} from "react-redux";
import {
    Email,
    Verified
} from "./user-table-col";
import {
  deleteSocialUser,
  deleteUser,
  getSocialUsers,
  getUsers,

} from "../../store/users/actions";
import MUIDataTable from "mui-datatables";
import {NotificationManager} from "react-notifications";

const UserList = () => {
    //meta title
    document.title = "User List";
    const [data, setData] = useState([])
    const dispatch = useDispatch();
    const {users} = useSelector((state) => ({
        users: state.users.users,
    }));
    const {socialUsers} = useSelector((state) => ({
        socialUsers: state.users.socialUsers,
    }));
    useEffect(() => {
        dispatch(getUsers());
        dispatch(getSocialUsers())
    }, []);
    useEffect(() => {
        let data = [];
        if (users && users.length > 0) {
            users.forEach((user) => {
                let attributes = user["Attributes"];
                let object = {};
                if (attributes) {
                    object.id = user["Username"]
                    if (attributes.filter((attribute) => attribute["Name"] === "picture")) {
                        const image = attributes.filter(
                            (attribute) => attribute["Name"] === "picture"
                        )[0]
                        if (image) {
                            object.image = attributes.filter(
                                (attribute) => attribute["Name"] === "picture"
                            )[0]["Value"];
                        }
                    }
                    if (attributes.filter((attribute) => attribute["Name"] === "given_name")) {
                        const givenName = attributes.filter(
                            (attribute) => attribute["Name"] === "given_name"
                        )[0]
                        if (givenName) {
                            object.firstName = attributes.filter(
                                (attribute) => attribute["Name"] === "given_name"
                            )[0]["Value"];
                        }
                    }
                    if (attributes.filter((attribute) => attribute["Name"] === "name")) {
                        const name = attributes.filter(
                            (attribute) => attribute["Name"] === "name"
                        )[0]
                        if (name) {
                            object.name = attributes.filter(
                                (attribute) => attribute["Name"] === "name"
                            )[0]["Value"];
                        }
                    }
                    if(attributes.filter((attribute) => attribute["Email"] === "email")){
                        object.email = attributes.filter(
                            (attribute) => attribute["Name"] === "email"
                        )[0]["Value"];
                    }
                    if(attributes.filter((attribute) => attribute["Name"] === "email_verified")){
                        const attribute = attributes.filter(
                            (attribute) => attribute["Name"] === "email_verified"
                        )[0]
                        if(attribute && attribute["Value"]){
                            object.verified = attributes.filter(
                                (attribute) => attribute["Name"] === "email_verified"
                            )[0]["Value"];
                        }
                    }
                    object.creationDate = new Date(
                        user["UserCreateDate"]
                    ).toLocaleDateString();
                    object.type = "cognito"
                    object.count = user.count
                    data.push(object);
                }
            });
        }
        if (socialUsers && socialUsers.length > 0) {
            socialUsers.forEach((user) => {
                let object = {};
                object.id = user.id
                object.image = undefined
                object.firstName = user.given_name;
                object.name = user.last_name;
                object.email = user.email;
                object.creationDate = user.createdAt
                object.verified = "true"
                object.type = user.login_type ? user.login_type : "social"
                data.push(object)
            })
        }
        setData(data)
    }, [users, socialUsers]);

    const [deletedUser, setDeletedUser] = useState()


    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "firstName",
            label: "First Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "creationDate",
            label: "Creation Date",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "verified",
            label: "Verified",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "type",
            label: "Type",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "count",
            label: "# of Audios",
            options: {
                filter: true,
                sort: true
            }
        }
    ];


    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const handleDeleteUser = () => {
        NotificationManager.info("Deletion Started!");
        switch (deletedUser.type){
          case "cognito":
            dispatch(deleteUser(deletedUser["id"]))
            NotificationManager.success("Deletion Completed!");
            break;
          case "social": case "google": case "apple":
            dispatch(deleteSocialUser(deletedUser["email"]))
            NotificationManager.success("Deletion Completed!");
            break;
        }
        setDeleteModal(false);
    };

    const options = {
        filterType: 'dropdown',
        filter: true,
        responsive: "standard",
        selectableRows: 'single',
        selectableRowsOnClick: true,
        onRowsDelete: (rowsDeleted) => {
            const selectedUser = data[rowsDeleted.data[0].dataIndex]
            setDeletedUser(selectedUser)
            setDeleteModal(true)
        },
        rowsPerPage: 15,
    };


    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteUser}
                onCloseClick={() => setDeleteModal(false)}
                title={"Are you sure you want to permanently delete the user?"}
            />
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Users" breadcrumbItem="User List"/>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <MUIDataTable
                                        title={"User List"}
                                        data={data}
                                        columns={columns}
                                        options={options}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(UserList);
