import {
  GET_POIS_SUCCESS,
  GET_POIS_ERROR,
  ADD_POI_SUCCESS,
  ADD_POI_ERROR,
  EDIT_POI_SUCCESS,
  EDIT_POI_ERROR,
  DELETE_POI_SUCCESS,
  DELETE_POI_ERROR,
} from "./actionTypes";

const INIT_STATE = {
  list: [],
  addedPoi: {},
  error: {},
};

const pois = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_POIS_SUCCESS:
      return {
        ...state,
        list: action.payload.data,
      };

    case GET_POIS_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_POI_SUCCESS:
      return {
        ...state,
        addedPoi: action.payload,
      };

    case ADD_POI_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case EDIT_POI_SUCCESS:
      return {
        ...state,
        list: state.list.map((poi) =>
          poi.id.toString() === action.payload.id.toString()
            ? { poi, ...action.payload }
            : poi
        ),
      };

    case EDIT_POI_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_POI_SUCCESS:
      return {
        ...state,
        list: state.list.filter(
          (poi) => poi.id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_POI_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default pois;
