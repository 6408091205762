import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";

import SimpleMap from "../Maps/LeafletMap/SimpleMap";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import DeleteModal from "../../components/Common/DeleteModal";
import {
  deleteRequest,
  getRequests, setRequestFilter,
} from "../../store/requests/actions";
import { getUsers } from "../../store/users/actions";
import { getAudios } from "../../store/audio/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import EditRequest from "./modals/edit-request";
import CreatePoiFromRequest from "./modals/create-poi-from-request";

const RequestList = () => {
  const [pageSize, setPageSize] = useState(10)
  const [currentState, setCurrentState] = useState(0);
  const [pageState, setPageState] = useState(0);
  const [modalEdit, setModalEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const [editedRequest, setEditedRequest] = useState(null);
  const [createdRequest, setCreatedRequest] = useState(null);
  const [deletedRequest, setDeletedRequest] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleDeleteRequest = () => {
    NotificationManager.info("Deletion Started!");
    dispatch(deleteRequest(deletedRequest));
    setDeleteModal(false);
    dispatch(getRequests());
    NotificationManager.success("Deletion Completed!");
  };

  const handleStatusFilter = (value) =>{
    dispatch(setRequestFilter(value.charAt(0).toLowerCase()+ value.slice(1)))
    dispatch(getRequests());
  }
  const onClickDelete = () => {
    setDeleteModal(true);
  };
  const { requests } = useSelector((state) => ({
    requests: state.requests.list,
  }));
  const [searchedName, setSearchedName] = useState()
  const [searchedRequests, setSearchedRequests] = useState(requests.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)))
  useEffect(() => {
    setPageState(Math.ceil(searchedRequests.length / pageSize));
  }, [searchedRequests,pageSize]);
  const handlePagination = (e, currentPage) => {
    e.preventDefault();
    setCurrentState(currentPage);
  };
  const handleSearchedItems = (value) =>{
    setSearchedName(value)
  }
  useEffect(() => {
    if(searchedName && requests){
      let searchedRequests = requests.filter((request) => request.requestFrom && request.requestFrom.includes(searchedName))
      setSearchedRequests(searchedRequests)
    }
    else if(requests){
      setSearchedRequests(requests)
    }
  }, [searchedName,requests]);
  const handlePageSize = (value) =>{
    setPageState(Math.ceil(searchedRequests.length / value));
    setPageSize(value)
  }

  const { users } = useSelector((state) => ({
    users: state.users.users,
  }));
  useEffect(() => {
    dispatch(getRequests());
    dispatch(getUsers());
    dispatch(getAudios());
  }, []);

  const getRequestFrom = (userId) => {
    let userName = userId;
    if (userId && users) {
      users.forEach((user) => {
        if (user["Attributes"][0]["Value"] === userId) {
          userName =
            user["Attributes"][3]["Value"] +
            " " +
            user["Attributes"][2]["Value"];
        }
      });
    }
    return userName;
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const createRequestLayout = () => {
    let layout = [];
    searchedRequests.slice(currentState * pageSize, (currentState +1 )* pageSize).forEach((request, index) => {
      layout.push(
        <Col key={index} md="4">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Request</h4>
              <p>From: {getRequestFrom(request.requestFrom)}</p>
              <p>Request Date: {request.createdAt}</p>
              <div id="leaflet-map" className="leaflet-map mb-1">
                <SimpleMap
                  lat={request.location.lat}
                  lng={request.location.lng}
                />
              </div>
              <p>Status: {capitalizeFirstLetter(request.status)}</p>
              <Button
                style={{ marginRight: 5 }}
                color="success"
                className="inner"
                onClick={() => {
                  setEditedRequest(request);
                  setModal(true);
                }}
              >
                Edit
              </Button>
              <Button
                color="danger"
                className="inner"
                onClick={() => {
                  setDeletedRequest(request);
                  onClickDelete(request);
                }}
              >
                Delete
              </Button>
              {request.status === "accepted" && (
                <Button
                  style={{ marginLeft: 5 }}
                  color="primary"
                  className="inner"
                  onClick={() => {
                    setCreatedRequest(request);
                    toggle();
                  }}
                >
                  Create POI From Request
                </Button>
              )}
            </CardBody>
          </Card>
        </Col>
      );
    });
    return layout;
  };
  const toggle = () => {
    setModal(!modal);
  };
  const toggleEdit = () => {
    setModalEdit(!modalEdit);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Request" breadcrumbItem="List" />
          <Form>
            <FormGroup defaultValue={20} onChange={event => handlePageSize(event.target.value)}>
              <Label for="exampleSelect">
                Page Size
              </Label>
              <Input
                  id="exampleSelect"
                  name="select"
                  type="select"
              >
                <option>
                  10
                </option>
                <option>
                  20
                </option>
                <option>
                  30
                </option>
                <option>
                  40
                </option>
                <option>
                  50
                </option>
              </Input>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup onChange={event => handleSearchedItems(event.target.value)}>
              <Label for="exampleSearch">
                Search
              </Label>
              <Input
                  id="exampleSearch"
                  name="search"
                  placeholder="Name"
                  type="search"
              />
            </FormGroup>
          </Form>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteRequest}
            onCloseClick={() => setDeleteModal(false)}
            title={"Are you sure you want to permanently delete the request?"}
          />
          <Form>
            <FormGroup defaultValue={20} onChange={event => handleStatusFilter(event.target.value)}>
              <Label for="exampleSelect">
                Filter
              </Label>
              <Input
                  id="exampleSelect"
                  name="select"
                  type="select"
              >
                <option>
                  All
                </option>
                <option>
                  Requested
                </option>
                <option>
                  Accepted
                </option>
                <option>
                  Rejected
                </option>
              </Input>
            </FormGroup>
          </Form>
          <Row>{searchedRequests && searchedRequests.length > 0 && createRequestLayout()}</Row>

          {editedRequest && <EditRequest editedRequest={editedRequest} modal={modal} setModal={setModal} setEditedRequest={setEditedRequest}/>}

          {createdRequest && <CreatePoiFromRequest createdRequest={createdRequest} modal={modal} setModal={setModal} setCreatedRequest={setCreatedRequest}/>}
          <Pagination aria-label="Page navigation example">
            <PaginationItem disabled={currentState <= 0}>
              <PaginationLink previous href="#" />
            </PaginationItem>
            {[...Array(pageState)].map((page, i) => (
                <PaginationItem active={i === currentState} key={i}>
                  <PaginationLink onClick={e => handlePagination(e, i)} href="#">
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
            ))}
            <PaginationItem  disabled={currentState >= pageState - 1}>
              <PaginationLink next href="#" />
            </PaginationItem>
          </Pagination>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default RequestList;
