import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";

import SimpleMap from "../Maps/LeafletMap/SimpleMap";
import { useDispatch, useSelector } from "react-redux";
import { deleteRoute, editRoute, getRoutes } from "../../store/routes/actions";
import { NotificationManager } from "react-notifications";
import DeleteModal from "../../components/Common/DeleteModal";
import { getAudios } from "../../store/audio/actions";
import { getPois } from "../../store/pois/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const RouteList = () => {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const [editedRoute, setEditedRoute] = useState(null);
  const [deletedRoute, setDeletedRoute] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selects, setSelects] = useState([]);
  const [selectedAudios, setSelectedAudios] = useState([]);
  const [selectedPois, setSelectedPois] = useState([]);
  const [page, setPage] = useState(1);
  const handleDeleteSelect = (index) => {
    const updatedSelectsPois = [...selectedPois];
    updatedSelectsPois.splice(index, 1);
    setSelectedPois(updatedSelectsPois);

    const updatedSelectsAudios = [...selectedAudios];
    updatedSelectsAudios.splice(index, 1);
    setSelectedAudios(updatedSelectsAudios);

    const updatedSelects = [...selects];
    updatedSelects.splice(index, 1);
    setSelects(updatedSelects);
  };
  const createPois = (select, index) => {
    let component = [];
    if (pois && pois.length > 0) {
      pois.forEach((poi) => {
        component.push(<option value={poi.id}>{poi.name}</option>);
      });
    }
    return (
      <select
        className="form-control"
        value={selects[index].poi.id}
        onChange={(e) => {
          handlePoiChange(index, e.target.value);
        }}
      >
        {component}
      </select>
    );
  };
  const createAudios = (select, index) => {
    let component = [];
    if (audios && audios.length > 0) {
      audios.forEach((audio) => {
        component.push(<option value={audio.id}>{audio.name}</option>);
      });
    }
    return (
      <select
        className="form-control"
        value={selects[index].audio.id}
        onChange={(e) => handleAudioChange(index, e.target.value)}
      >
        {component}
      </select>
    );
  };
  const handlePoiChange = (index, value) => {
    const updatedSelects = [...selectedPois];
    updatedSelects[index] = value;
    setSelectedPois(updatedSelects);
  };
  const handleAudioChange = (index, value) => {
    const updatedSelects = [...selectedAudios];
    updatedSelects[index] = value;
    setSelectedAudios(updatedSelects);
  };
  const { pois } = useSelector((state) => ({
    pois: state.pois.list,
  }));
  const { audios } = useSelector((state) => ({
    audios: state.audios.list,
  }));

  useEffect(() => {
    dispatch(getAudios());
    dispatch(getPois());
  }, []);
  const { routes } = useSelector((state) => ({
    routes: state.routes.list,
  }));

  const onClickDelete = (route) => {
    setDeleteModal(true);
    setDeletedRoute(route);
  };

  const handleDeleteRoute = () => {
    NotificationManager.info("Deletion Started!");
    dispatch(deleteRoute(deletedRoute));
    setDeleteModal(false);
    dispatch(getRoutes());
    NotificationManager.success("Deletion Completed!");
  };
  const handlePageClick = (page) => {
    setPage(page);
  };
  const toggle = () => {
    setModal(!modal);
  };
  useEffect(() => {
    dispatch(getRoutes());
  }, []);

  const handleAddSelect = () => {
    if (selects.length < 20) {
      setSelects([...selects, { selectedOption: "" }]);
    } else {
      NotificationManager.error("You cannot add more then 20 points!");
    }
  };
  const createRoutes = () => {
    let component = [];
    routes.forEach((route) => {
      component.push(
        <Col xl="4" sm="6">
          <Card>
            <CardBody>
              <CardTitle tag="h5">{route.name}</CardTitle>
              <CardSubtitle>{route.date}</CardSubtitle>
              <div id="leaflet-map" className="leaflet-map">
                {route.routes[0].poi &&
                  route.routes[0].poi.lat &&
                  route.routes[0].poi.lng && (
                    <SimpleMap
                      lat={route.routes[0].poi.lat}
                      lng={route.routes[0].poi.lng}
                      routes={route.routes}
                    />
                  )}
              </div>

              <Button
                onClick={() => {
                  setSelectedAudios(
                    route.routes.map((route) => route.audio.id)
                  );
                  setSelectedPois(route.routes.map((route) => route.poi.id));
                  setSelects(route.routes);
                  setEditedRoute(route);
                  toggle();
                }}
                className="p-2 m-2"
                type={"button"}
                color="success"
              >
                Edit
              </Button>
              <Button
                onClick={() => onClickDelete(route)}
                className="p-2 m-2"
                color="danger"
                type={"button"}
              >
                Delete
              </Button>
            </CardBody>
          </Card>
        </Col>
      );
    });
    return component;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    if (
      selectedAudios &&
      selectedAudios.length > 0 &&
      selectedPois &&
      selectedPois.length > 0
    ) {
      let routes = [];
      for (let i = 0; i < selectedAudios.length; i++) {
        routes.push({
          audio: selectedAudios[i],
          poi: selectedPois[i],
        });
      }
      NotificationManager.info("Update Started!");
      const object = {
        id: editedRoute.id,
        name: data.get("routeName"),
        description: data.get("description"),
        date: new Date().toLocaleDateString(),
        routes: routes,
      };
      dispatch(editRoute(object));
      dispatch(getRoutes());
      NotificationManager.success("Update Successful!");
    } else {
      NotificationManager.error("Please Add at least one POI and Audio");
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Routes" breadcrumbItem="List" />
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteRoute}
            onCloseClick={() => setDeleteModal(false)}
            title={"Are you sure you want to permanently delete the route?"}
          />
          {routes && routes.length > 0 && createRoutes()}
          <Row>
            <Col lg="12">
              <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    previous
                    href="#"
                    onClick={() => handlePageClick(page - 1)}
                  />
                </PaginationItem>
              </ul>
            </Col>
          </Row>
          {editedRoute && (
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                Edit Route
              </ModalHeader>
              <ModalBody>
                <Form onSubmit={handleSubmit}>
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="routeName"
                      className="col-form-label col-lg-2"
                    >
                      Poi Name
                    </Label>
                    <Col lg="10">
                      <Input
                        id="routeName"
                        name="routeName"
                        type="text"
                        className="form-control"
                        placeholder="Enter Poi Name..."
                        defaultValue={editedRoute.name}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label className="col-form-label col-lg-2">
                      Poi Description
                    </Label>
                    <Col lg="10">
                      <Input
                        id="description"
                        name="description"
                        type="textarea"
                        defaultValue={editedRoute.description}
                      />
                    </Col>
                  </FormGroup>
                  <div className="inner form-group mb-0 row">
                    <Label className="col-form-label col-lg-2">Points</Label>
                    <div className="inner col-lg-10 ml-md-auto">
                      <div className="mb-3 row align-items-center">
                        {selects.map((select, index) => (
                          <Row className="mt-1" key={index}>
                            <Col md="5">{createPois(select, index)}</Col>
                            <Col md="5">{createAudios(select, index)}</Col>
                            <Col md="2">
                              <Button
                                color="danger"
                                onClick={() => handleDeleteSelect(index)}
                              >
                                Delete
                              </Button>
                              <Button onClick={handleAddSelect} color="success">
                                Add
                              </Button>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </div>
                  </div>

                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button type="submit" color="primary">
                        Edit Route
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RouteList;
