import React, {useEffect, useState} from "react";

import {Container} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {useDispatch, useSelector} from "react-redux";
import {getPois} from "../../store/pois/actions";
import {Map, Marker, Popup, TileLayer} from "react-leaflet";
import useWindowDimensions from "../../helpers/common_functions_helper";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
const blueIcon = L.icon({
    iconUrl: require("../../assets/images/icons/marker-blue.png"),
    iconSize:     [32, 32], // size of the icon
});
const orangeIcon = L.icon({
    iconUrl: require("../../assets/images/icons/orange-circle.png"),
    iconSize:     [24, 24], // size of the icon
});
const redIcon = L.icon({
    iconUrl: require("../../assets/images/icons/green-circle.png"),
    iconSize:     [32, 32], // size of the icon
});

const PoiOverview = () => {
    const [routes, setRoutes] = useState([])
    const dispatch = useDispatch();
    const {pois} = useSelector((state) => ({
        pois: state.pois.list,
    }));
    const { height, width } = useWindowDimensions();
    useEffect(() => {
        dispatch(getPois());
    }, []);

    useEffect(() => {
        let data = []
        if (pois && pois.length > 0) {
            pois.forEach((poi) => {
                if(poi.location && poi.location.lat && poi.location.lng){
                    data.push({
                        poi: {
                            icon: "blue",
                            name: poi.name,
                            lat: poi.location.lat,
                            lng: poi.location.lng
                        }
                    })
                }
                if(poi.triggerPoints){
                    if(poi.triggerPoints[0] && poi.triggerPoints[0].coordinate){
                        data.push({
                            poi:{
                                icon: "red",
                                name: poi.name + " Trigger Point 1",
                                lat: poi.triggerPoints[0].coordinate.lat,
                                lng: poi.triggerPoints[0].coordinate.lng
                            }
                        })
                    }
                    if(poi.triggerPoints[1] && poi.triggerPoints[1].coordinate){
                        data.push({
                            poi:{
                                icon: "orange",
                                name: poi.name + " Trigger Point 2",
                                lat: poi.triggerPoints[1].coordinate.lat,
                                lng: poi.triggerPoints[1].coordinate.lng
                            }
                        })
                    }
                }
            })
            setRoutes(data)
        }

    }, [pois])

    const createIcon=(iconType) =>{
        switch(iconType){
            case "blue":
                return blueIcon
            case "red":
                return redIcon
            case "orange":
                return orangeIcon
        }
    }

    const createMarkers = () => {
        let component = [];
        if (routes && routes.length >0) {
            routes.forEach((route, index) => {
                if(route.poi.lat && route.poi.lng){
                    component.push(
                        <Marker key={index} icon={createIcon(route.poi.icon)} position={[route.poi.lat, route.poi.lng]}>
                            <Popup>{route.poi.name}</Popup>
                        </Marker>
                    );
                }
            });
        }
        return component;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Poi" breadcrumbItem="Overview"/>
                    <Map
                        center={[48.13743,11.57549]}
                        zoom={10}
                        style={{height: height,width:width}}
                    >
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {routes && createMarkers()}
                    </Map>

                </Container>
            </div>
        </React.Fragment>
    )

}
export default PoiOverview
